import React, { useEffect,useState, ChangeEvent, FormEvent } from "react";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Link, Navigate } from 'react-router-dom';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

//import images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import bell from "../../assets/images/svg/bell.svg";
import { api } from "../../../utils/api";

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = () => {
    
    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const [notifications, setNotifications] = useState([]);
    const navigate = useNavigate();

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
      const fetchData = async () => {
        try {          
          const responseDemmand = await api.get(`/getUserLateDemmand`);
          setNotifications(responseDemmand.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };
    const updateDemmand = (id) => {
        const updateAlert = api.get(`/updateDemmandAlert/${id}`).then(response => {
            fetchData();
            navigate(`/editar-demanda/${id}`);
          });
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{notifications.length}</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="p-4">
                            <SimpleBar style={{ maxHeight: "300px" }}>
                                <ul className="list-unstyled timeline-list">
                                    {notifications.map(notification => (
                                        <li key={notification.id}>
                                            <Link onClick={() => updateDemmand(notification.id)}>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-0">{notification.demand_name}</h6>
                                                        <p className="text-muted mb-0">{formatDate(notification.venc_date)}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </SimpleBar>
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;