import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../slices/thunks";

import logoLight from "../../assets/images/logo.png";
//import images

const Login = (props) => {
    const dispatch = useDispatch();
    const { user, error, loading, errorMsg } = useSelector(state => ({
        user: state.Account.user,
        error: state.Login.error,
        loading: state.Login.loading,
        errorMsg: state.Login.errorMsg,
    }));

    const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const [erroMessage, seterroMessage] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);

    
    useEffect(() => {
        if (user && user) {
            const updatedUserData = process.env.REACT_APP_DEFAULTAUTH === "firebase" ? user.multiFactor.user.email : user.user.email;
            const updatedUserPassword = process.env.REACT_APP_DEFAULTAUTH === "firebase" ? "" : user.user.confirm_password;
            setUserLogin({
                email: updatedUserData,
                password: updatedUserPassword
            });
        }
    }, [user]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Digite o e-mail"),
            password: Yup.string().required("Digite a senha"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        }
    });

    const signIn = type => {
        dispatch(socialLogin(type, props.router.navigate));
    };

    //handleTwitterLoginResponse
    // const twitterResponse = e => {}

    //for facebook and google authentication
    const socialResponse = type => {
        signIn(type);
    };


    useEffect(() => {
        if (errorMsg) {
            seterroMessage(true);
            setPasswordInvalid(true); // Set passwordInvalid to true when there is an error message
            setTimeout(() => {
              dispatch(resetLoginFlag());
            }, 3000);
          }
    }, [dispatch, errorMsg]);

    document.title = "Entrar | Geld";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content"  style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "120vh" }}>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4">
                                </div>
                            </Col>
                        </Row>
                        <Row tabIndex="2">
                            <Col xs="6" className="mx-auto rounded-4" style={{ backgroundColor: '#42428685' }}>
                                    <CardBody>
                                        <div className="text-center mt-2" style={{ margin: "0 auto", maxWidth: "300px" }}>
                                            <img src={logoLight} alt="" style={{ width: "100%", height: "auto" }}/>
                                        </div>
                                    </CardBody>
                                <Col>
                                    <Card className="mt-4 cardBgLogin" >
                                        <CardBody className='rounded-4' style={{ backgroundColor: '#3144c9' }}>
                                            {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                            <div className="p-2 mt-4">
                                                <Form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    action="#">

                                                    <div className="mb-3">
                                                        <Label htmlFor="email" className="form-label">E-mail</Label>
                                                        <Input
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Enter email"
                                                            type="email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {erroMessage ? (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="float-end">
                                                            <Link to="/forgot-password" className="text-white"> <u>Esqueceu a sua senha?</u></Link>
                                                        </div>
                                                        <Label className="form-label" htmlFor="password-input">Senha</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Input
                                                                name="password"
                                                                value={validation.values.password || ""}
                                                                type={passwordShow ? "text" : "password"}
                                                                className="form-control pe-5"
                                                                placeholder="Enter Password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                invalid={
                                                                    (validation.touched.password && validation.errors.password) ||
                                                                    passwordInvalid // Add passwordInvalid to the invalid prop
                                                                }
                                                            />
                                                            {erroMessage ? (
                                                                <FormFeedback type="invalid">E-mail ou senha incorretos</FormFeedback>
                                                            ) : null}
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                        </div>
                                                    </div>

                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                        <Label className="form-check-label" htmlFor="auth-remember-check">Lembre-me</Label>
                                                    </div>

                                                    <div className="mt-4">
                                                        <Button disabled={error ? null : loading ? true : false} className="btn btn-primary w-100 btn-primary-login" type="submit">
                                                            {loading ? <Spinner size="sm" className='me-2'> Carregando... </Spinner> : null}
                                                            Entrar
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Card>

                                    {/* <div className="mt-4 text-center">
                                        <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                                    </div> */}

                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);