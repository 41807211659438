export function getDemmandStatusTextFormated(status: string) {
    switch (status) {
        case 'delivered':
            return 'Entregue';
        case 'late':
            return 'Atrasado';
        case 'canceled':
            return 'Cancelado';
        case 'pending':
            return 'Pendente';
        default:
            return '';
    }
}