import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Spinner, UncontrolledDropdown } from 'reactstrap';
import { api } from '../../utils/api';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from "react-router-dom";

import CustomPagination from "../../components/customPagination.js"

const BaseExampleVoters = ({
  tableData,
  communicationId,
  onDelete,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
  total,
  isLoading,
  handleSort,
}) => {
  const tableRef = useRef(null);

  const handleDelete = (voterId) => {
    onDelete(voterId);
  };
  const handleEdit = (rowIndex) => {
    console.log('Edit row:', rowIndex);
  };

  const handleExportExcel = () => {
    const excelData = tableData.map((item) => ({
      Nome: item.nome,
      Cargo: item.cargo,
      Telefone: item.telefone,
      Bairro: item.bairro,
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelDataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelDataBlob, 'Eleitores.xlsx');
  };

  const handleExportPDF = () => {
    const table = tableRef.current;

    html2canvas(table).then((canvas) => {
      const tableImage = canvas.toDataURL('image/png');
      const pdfWidth = table.offsetWidth;
      const pdfHeight = table.offsetHeight;

      const doc = new jsPDF({
        orientation: 'l',
        unit: 'px',
        format: [pdfWidth, pdfHeight],
      });

      doc.addImage(tableImage, 'PNG', 0, 0, pdfWidth, pdfHeight);
      doc.save('Eleitores.pdf');
    });
  };

  const handleFormSubmit = async (formData) => {
    try {
      // Send form data to the server
      await api.post('/submitForm', formData);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>Nome</span>,
      selector: row => row.nome,
      sortable: true,
      cell: (row) => <div style={{ whiteSpace: 'normal' }}>{row.nome}</div>,
    },
    {
      name: <span className='font-weight-bold fs-13'>Grupo</span>,
      selector: row => row.cargo,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Telefone</span>,
      selector: row => row.telefone,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Bairro</span>,
      selector: row => row.bairro,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Ação</span>,
      sortable: true,

      cell: (row) => {
        const { id } = row;
        return (
          <button className="btn btn-ghost-danger waves-effect waves-light"> <a onClick={() => handleDelete(id)}><i className="ri-delete-bin-line"></i></a></button>
        );
      },
    },
    {
      name: <span className='font-weight-bold fs-13'></span>,
      sortable: false,
      cell: (row, index) => {
        return (
          <i
            className={`ri-check-line ${row.last_send_success && row.last_send_success === 'Y' ? '' : 'hide-icon'}`}
          ></i>
        );
      },
    },
  ];
  const customButtonStyle = {
    marginRight: '10px',
  };
  return (
    <>
      <div ref={tableRef}>
        <DataTable columns={columns} data={tableData} pagination
          onSort={handleSort}
          paginationServer
          sortServer
          defaultSortFieldId={1}
          defaultSortAsc
          progressPending={isLoading}
          progressComponent={<Spinner />}
          paginationTotalRows={total}
          paginationPerPage={rowsPerPage}
          paginationComponent={CustomPagination}
          noDataComponent={<div>Nenhum registro encontrado</div>}
          paginationComponentOptions={{
            rowsPerPageText: 'Dados por Pagina:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        >
        </DataTable>
      </div>
    </>
  );
};

export default BaseExampleVoters;
