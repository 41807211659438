import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Spinner } from 'reactstrap';
import 'jspdf-autotable';
import CustomPagination from "../../components/customPagination.js"

const TableListUserLoginHistory = ({
  tableData,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
  total,
  isLoading,
  handleSort,
}) => {
  const tableRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(tableData); // Initialize filteredData with the original tableData
  }, [tableData]);


  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>ID</span>,
      selector: row => row.id,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Data do Acesso</span>,
      selector: row => row.created_at,
      sortable: true
    },
  ];

  return (
    <>
      <div ref={tableRef}>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          onSort={handleSort}
          paginationServer
          sortServer
          defaultSortFieldId={1}
          defaultSortAsc={false}
          progressPending={isLoading}
          progressComponent={<Spinner />}
          noDataComponent={<div>Nenhum registro encontrado</div>}
          paginationTotalRows={total}
          paginationPerPage={rowsPerPage}
          paginationComponent={CustomPagination}
          paginationComponentOptions={{
            rowsPerPageText: 'Dados por Pagina:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        >
        </DataTable>
      </div>
    </>
  );
};

export default TableListUserLoginHistory;
