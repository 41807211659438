import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form, FormFeedback, Input } from 'reactstrap';
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import Swal from 'sweetalert2';

import AsyncSelect from "react-select/async";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { api } from "../../utils/api";
import Cleave from "cleave.js/react";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export function NewServices() {
    const navigate = useNavigate();
    const [optionsUserId, setOptionsUserId] = useState<Option[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [selectedVoter, setSelectedVoter] = useState<any>(null);

    type Option = {
        id: number;
        name: string;
    };
    const [formData, setFormData] = useState({
        voter_id: '',
        open_date: '',
        venc_date: '',
        user_id: '',
        demand_name: '',
        category: '',
        type: '',
        demand_origin: '',
        priority: '',
        status: '',
        zip_code: '',
        address: '',
        number: '',
        address_complement: '',
        neighborhood: '',
        city: '',
        state: '',
        photo: '',
        description: '',
        created_by: '',
        commentary: ''

    });
    const [formValidationMessages, setFormValidationMessages] = useState({
        voter_id: '',
        open_date: '',
        venc_date: '',
        demand_name: '',
        priority: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
    };

    const handleVoterChange = (option: any) => {
        setSelectedVoter(option ?? null);
        setFormData({ ...formData, voter_id: option?.value ?? '' });
    };

    const handleUserChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, user_id: event.target.value });
    };

    const handlePriorityChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, priority: event.target.value });
    };

    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, status: event.target.value });
    };

    const handleChangeCep = async (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, zip_code: event.target.value });

        if (event.target.value.length <= 8) {
            return;
        }

        const responseGet = await api.get('/getCep/' + event.target.value);
        if (responseGet != "failed") {
            setFormData((prevState) => ({
                ...prevState,
                address: responseGet.logradouro?.toUpperCase() ?? '',
                number: formData.number ?? '',
                neighborhood: responseGet.bairro?.toUpperCase() ?? '',
                city: responseGet.localidade?.toUpperCase() ?? '',
                state: responseGet.uf?.toUpperCase() ?? '',
                address_complement: responseGet.complemento?.toUpperCase() ?? '',
            }));

        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const responseUsers = await api.get('/getUsersSelect');
            setOptionsUserId(responseUsers.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const isValid = validateFormData();
        if (!isValid) {
            Swal.fire('Erro', `Verifique os erros do formulário`, 'error');
            return false;
        }

        var formFile = new FormData();
        if (files.length) {
            formFile.append('image', files[0], files[0].name);
        }
        Object.entries(formData).forEach(([key, value]) => {
            formFile.append(key, String(value));
        });
        api.post('/createDemmand', formFile)
            .then(response => {
                window.location.href = window.location.href;
            })
            .catch(error => {
                // Handle the error response
                console.error(error);
                Swal.fire('Erro', 'Falha na criação da demanda!', 'error');

            });
    };

    function validateFormData() {
        const errors = {
            voter_id: '',
            open_date: '',
            venc_date: '',
            demand_name: '',
            priority: '',
        };

        let hasError = false;

        if (!formData.voter_id) {
            hasError = true;
            errors.voter_id = 'O eleitor é obrigatório';
        }
        if (!formData.demand_name) {
            hasError = true;
            errors.demand_name = 'O título da demanda é obrigatório';
        }
        if (!formData.priority) {
            hasError = true;
            errors.priority = 'A prioridade da demanda é obrigatória';
        }
        if (!formData.open_date) {
            hasError = true;
            errors.open_date = 'A data de abertura é obrigatória';
        }
        if (!formData.venc_date) {
            hasError = true;
            errors.venc_date = 'A data de entrega é obrigatória';
        }

        setFormValidationMessages(errors);

        return !hasError;
    }

    document.title = "Demandas | Geld";
    const [phone, setPhone] = useState("");

    const [selectedSingle, setSelectedSingle] = useState(null);

    function onPhoneChange(e: { target: { rawValue: React.SetStateAction<string>; }; }) {
        setPhone(e.target.rawValue);
    }

    async function searchVoters(input: string) {
        if (input.length < 2) {
            return [];
        }

        const response = await api.get('searchVoters/name', { params: { name: input } });

        return response.map((option: any) => ({
            value: option.id,
            label: option.name,
        })) as any;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Nova Demanda</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <span className="text-danger">* Campos obrigatórios</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="handleGroupChange" className="form-label">
                                                        Eleitor <span className="text-danger">*</span>
                                                    </label>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={searchVoters}
                                                        value={selectedVoter}
                                                        onChange={handleVoterChange}
                                                        placeholder="Selecione um eleitor"
                                                        noOptionsMessage={({ inputValue }) => inputValue.length < 2 ? 'Digite 2 ou mais caracteres para buscar' : 'Nenhum resultado encontrado'}
                                                        loadingMessage={({ inputValue }) => "Carregando"}
                                                        className={!!formValidationMessages.voter_id ? 'border border-danger' : ''}
                                                    />
                                                    {!!formValidationMessages.voter_id && (
                                                        <small className="text-danger">
                                                            {formValidationMessages.voter_id}
                                                        </small>
                                                    )}
                                                    {/* <Select
                                                        options={optionsVoterId.map((option) => ({
                                                            value: option.id,
                                                            label: option.name,
                                                        })) as any}
                                                        value={formData.voter_id}
                                                        onChange={handleVoterChange}
                                                    /> */}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="open_date" className="form-label">
                                                        Data de abertura <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="open_date"
                                                        name="open_date"
                                                        value={formData.open_date}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.open_date}
                                                    />
                                                    {!!formValidationMessages.open_date && (
                                                        <small className="text-danger">
                                                            {formValidationMessages.open_date}
                                                        </small>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="venc_date" className="form-label">
                                                        Data previsa de entrega <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="venc_date"
                                                        name="venc_date"
                                                        value={formData.venc_date}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.venc_date}
                                                    />
                                                    {!!formValidationMessages.venc_date && (
                                                        <small className="text-danger">
                                                            {formValidationMessages.venc_date}
                                                        </small>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="handleUserChange" className="form-label">Responsável</label>
                                                    <select className="form-select mb-3" id="handleUserChange" aria-label="Default select example"
                                                        name="user_id"
                                                        value={formData.user_id}
                                                        onChange={handleUserChange}>
                                                        <option value="">Selecione um Responsável</option>
                                                        {optionsUserId.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}>
                                                <div>
                                                    <label htmlFor="demand_name" className="form-label">
                                                        Título da demanda <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="demand_name"
                                                        placeholder="Digite Titulo Demanda"
                                                        name="demand_name"
                                                        value={formData.demand_name}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.demand_name}
                                                    />
                                                    {!!formValidationMessages.demand_name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.demand_name}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div>
                                                    <label htmlFor="category" className="form-label">Categoria</label>
                                                    <input type="text" className="form-control" id="category" placeholder="Digite a Categoria"
                                                        name="category"
                                                        value={formData.category}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div>
                                                    <label htmlFor="type" className="form-label">Tipo</label>
                                                    <input type="text" className="form-control" id="type" placeholder="Digite Tipo"
                                                        name="type"
                                                        value={formData.type}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={4}>
                                                <div>
                                                    <label htmlFor="demand_origin" className="form-label">Origem da Demanda</label>
                                                    <input type="text" className="form-control" id="demand_origin" placeholder="Digite Origem Demanda"
                                                        name="demand_origin"
                                                        value={formData.demand_origin}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div>
                                                    <label htmlFor="choices-single-default" className="form-label">
                                                        Prioridade <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className={`form-select ${!!formValidationMessages.priority ? 'is-invalid' : ''}`}
                                                        aria-label="Default select example"
                                                        name="priority"
                                                        value={formData.priority}
                                                        onChange={handlePriorityChange}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="high">Alta</option>
                                                        <option value="average">Média</option>
                                                        <option value="low">Baixa</option>
                                                    </select>
                                                    {!!formValidationMessages.priority && (
                                                        <FormFeedback>
                                                            {formValidationMessages.priority}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div>
                                                    <label htmlFor="choices-single-default" className="form-label">Status</label>
                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                        name="status"
                                                        value={formData.status}
                                                        onChange={handleStatusChange}>
                                                        <option value="">Selecione</option>
                                                        <option value="delivered">Entregue</option>
                                                        <option value="late">Atrasado</option>
                                                        <option value="canceled">Cancelado</option>
                                                        <option value="pending">Pendente</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="zip_code" className="form-label">
                                                        CEP
                                                    </label>
                                                    <Cleave
                                                        placeholder="Buscar CEP"
                                                        options={{
                                                            delimiter: '-',
                                                            blocks: [5, 3],
                                                            numericOnly: true,
                                                        }}
                                                        className="form-control"
                                                        id="zip_code"
                                                        name="zip_code"
                                                        value={formData.zip_code}
                                                        onChange={handleChangeCep}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div>
                                                    <label htmlFor="address" className="form-label">
                                                        Endereço
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="number" className="form-label">Número</label>
                                                    <input type="number" className="form-control" id="number"
                                                        name="number"
                                                        value={formData.number}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="address_complement" className="form-label">Complemento</label>
                                                    <input type="text" className="form-control" id="address_complement"
                                                        name="address_complement"
                                                        value={formData.address_complement}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="neighborhood" className="form-label">Bairro</label>
                                                    <input type="text" className="form-control" id="neighborhood"
                                                        name="neighborhood"
                                                        value={formData.neighborhood}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="city" className="form-label">
                                                        Cidade
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="city"
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="state" className="form-label">
                                                        Estado (UF)
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="state"
                                                        name="state"
                                                        value={formData.state}
                                                        onChange={handleChange}
                                                        maxLength={2}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="description" className="form-label">Descrição</label>
                                                    <textarea className="form-control" id="description" name="description" onChange={handleChange} value={formData.description}></textarea>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="commentary" className="form-label">Comentário</label>
                                                    <textarea className="form-control" id="commentary" name="commentary" onChange={handleChange} value={formData.commentary}></textarea>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <label htmlFor="exampleFormControlTextarea5" className="form-label">Anexar Mídia</label>
                                                <FilePond
                                                    files={files}
                                                    onupdatefiles={(fileItems) => setFiles([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                    allowReorder={true}
                                                    allowMultiple={false}
                                                    labelIdle={`
                                                        <div class="d-flex flex-row align-items-center justify-content-center">
                                                            <img src="/icons/upload.png" />
                                                            <b style="color: #888; margin-left: 15px">Arraste ou clique para upload da foto</b>
                                                        </div>
                                                    `}
                                                    id="fileUpload"
                                                    name="fileUpload"
                                                />
                                            </Col>
                                        </Row>
                                        <button type="submit" className="btn btn-primary waves-effect waves-light">Salvar</button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
