import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form, Modal, ModalHeader, ModalBody, Button, ModalFooter, Input, FormFeedback } from 'reactstrap';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import Swal from 'sweetalert2';

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import "./Voters.css";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

import { api } from "../../utils/api";
import Flatpickr from 'react-flatpickr';
import { flatpickrLocale } from "../../utils/flatpickrLocale";
import useQueryParams from "../../hooks/queryParams";
import { removeNonAlphanumeric } from "../../utils/removeNonAlphanumeric";
import { GenericObject } from "../../types/GenericObject";

export function NewVotersLink() {
    const { getQueryParam } = useQueryParams();

    const [showOtherGender, setShowOtherGender] = useState(false);
    const [otherGender, setOtherGender] = useState('');
    const [gender, setGender] = useState('');
    const navigate = useNavigate();
    const [optionsGroupId, setOptionsGroupId] = useState<Option[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState<number>(0);
    const [useTermsAccepted, setUseTermsAccepted] = useState(false);
    const [lastSearchedCpf, setLastSearchedCpf] = useState('');
    const [isSearchingCpf, setIsSearchingCpf] = useState(false);

    const [modalUseTermsIsOpen, setModalUseTermsIsOpen] = useState(false);

    const [userIdLinkOwner, setUserIdLinkkOwner] = useState(0);

    type Option = {
        id: number;
        name: string;
    };
    const [formData, setFormData] = useState({
        cpf: '',
        name: '',
        phone: '',
        birth_date: '',
        mother_name: '',
        group_id: '',
        voter_registration: '',
        electoral_ward_id: '',
        section: '',
        zip_code: '',
        address: '',
        number: '',
        address_complement: '',
        neighborhood: '',
        city: '',
        state: '',
        comments: '',
        number_classification: 0,
        created_by: 0,
        created_by_description: '',
        created_by_recommendation:'',

        school: '',
    });
    const [formValidationMessages, setFormValidationMessages] = useState({
        cpf: '',
        name: '',
        phone: '',
        birth_date: '',
        mother_name: '',
        address: '',
        zip_code: '',
        email: '',
        voter_registration: '',
    });

    useEffect(() => {
        const linkOwnerId = Number(getQueryParam('user') || 0);
        let createdByDescription = '';

        if (linkOwnerId) {
            const savedCreatedByDescription = window.sessionStorage.getItem('createVotersLinkCreatedByDescription');
            if (savedCreatedByDescription) {
                createdByDescription = savedCreatedByDescription;
            }
        }

        setUserIdLinkkOwner(linkOwnerId);

        setFormData((prevState) => ({ ...prevState, created_by: linkOwnerId, created_by_description: createdByDescription, group_id: '' }));

    }, [getQueryParam]);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
    };

    const handleChangeCpf = async (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, cpf: event.target.value });

        if (isSearchingCpf) {
            return;
        }

        if (event.target.value.length < 14 || event.target.value === lastSearchedCpf) {
            return;
        }

        try {
            setIsSearchingCpf(true);
            setLastSearchedCpf(event.target.value);
            
            const cpfResponse = await api.get(`/getCpf/${removeNonAlphanumeric(event.target.value)}`);

            if (cpfResponse.status && cpfResponse.nome !== 'Test Token') {
                let extraInformations: GenericObject = {};

                if (cpfResponse.nascimento) {
                    const day = cpfResponse.nascimento.substring(0, 2);
                    const month = cpfResponse.nascimento.substring(3, 5);
                    const year = cpfResponse.nascimento.substring(6);
                    extraInformations.birth_date = `${day}/${month}/${year}`;
                }

                if (cpfResponse.mae) {
                    extraInformations.mother_name = String(cpfResponse.mae).toUpperCase();
                }

                if (cpfResponse.genero) {
                    extraInformations.gender = cpfResponse.genero === 'F' ? 'Feminino' : (cpfResponse.genero === 'M' ? 'Masculino' : 'Outros');
                }

                setFormData((prevState) => ({
                    ...prevState,
                    name: String(cpfResponse.nome || '').toUpperCase(),
                    ...extraInformations,
                }));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSearchingCpf(false);
        }
    };

    const handleBirthDateChange = (dateString: string) => {
        setFormData({ ...formData, birth_date: dateString });
    };

    const handleGroupChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, group_id: event.target.value });
    };

    const handleChangeCep = async (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, zip_code: event.target.value });

        if (event.target.value.length <= 8) {
            return;
        }

        const responseGet = await api.get('/getCep/' + event.target.value);
        if (responseGet != "failed") {
            setFormData((prevState) => ({
                ...prevState,
                address: responseGet.logradouro?.toUpperCase() ?? '',
                number: formData.number ?? '',
                neighborhood: responseGet.bairro?.toUpperCase() ?? '',
                city: responseGet.localidade?.toUpperCase() ?? '',
                state: responseGet.uf?.toUpperCase() ?? '',
                address_complement: responseGet.complemento?.toUpperCase() ?? '',
            }));
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const responseGroupSelect = await api.get('/getGroup');
            setOptionsGroupId(responseGroupSelect.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const isValid = validateFormData();
        if (!isValid) {
            Swal.fire('Erro', `Verifique os erros do formulário`, 'error');
            return false;
        }

        var formFile = new FormData();
        if (files.length) {
            formFile.append('image', files[0], files[0].name);
        }

        const fieldsWhenHaveUserIdLinkOwner = ['created_by_description', 'created_by', 'group_id'];

        Object.entries(formData).forEach(([key, value]) => {
            if (fieldsWhenHaveUserIdLinkOwner.includes(key)) {
                // Só irá adicionar esses campos à request quando tiver indicado o usuario que criou o link
                if (userIdLinkOwner) {
                    formFile.append(key, String(value));
                }
            } else {
                formFile.append(key, String(value));
            }
        });

        api.post('/createVotersLink', formFile)
            .then(response => {
                if (response.error && response.error == "errorCpf") {
                    Swal.fire('Erro', `Falha na criação de eleitor! <br> CPF Existente! (ELEITOR ID ${response.voterExist.id})`, 'error');
                    setFormValidationMessages((prevState) => ({ ...prevState, cpf: 'Já existe outro eleitor com esse CPF' }));
                } else if (response.error && response.error == "errorNameAndPhone") {
                    Swal.fire('Erro', `Falha na criação de eleitor! <br> Nome e Telefone Existente! (ELEITOR ID ${response.voterExist.id})`, 'error');
                    setFormValidationMessages((prevState) => ({
                        ...prevState,
                        name: 'Já existe outro eleitor com esse nome e telefone',
                        phone: 'Já existe outro eleitor com esse nome e telefone'
                    }));
                } else if (response.error && response.error == "errorPhone") {
                    Swal.fire('Erro', `Falha na criação de eleitor! <br> Telefone Existente! (ELEITOR ID ${response.voterExist.id})`, 'error');
                    setFormValidationMessages((prevState) => ({ ...prevState, phone: 'Já existe outro eleitor com esse telefone' }));
                } else if (response.error && response.error == "errorRegistration") {
                    Swal.fire('Erro', `Falha na criação de eleitor! <br> Título de eleitor Existente! (ELEITOR ID ${response.voterExist.id})`, 'error');
                    setFormValidationMessages((prevState) => ({ ...prevState, voter_registration: 'Já existe outro eleitor com esse título de eleitor' }));
                }else if (response.error && response.error == "error") {
                    Swal.fire('Erro', `Erro na criação do eleitor`, 'error');
                    console.log(response.erroMsg);
                } else {
                    // Salvar no storage o nome do responsavel para usos posteriores
                    window.sessionStorage.setItem('createVotersLinkCreatedByDescription', formData.created_by_description);

                    Swal.fire('Sucesso', 'Eleitor criado com Sucesso!', 'success').then(() => {
                        window.location.reload();
                    });
                }
            })
            .catch(error => {
                // Handle the error response
                console.error(error);
                Swal.fire('Erro', 'Falha na criação de eleitor!', 'error');
                //window.location.reload();

            });
    };

    function validateFormData() {
        const errors = {
            cpf: '',
            name: '',
            phone: '',
            birth_date: '',
            mother_name: '',
            address: '',
            zip_code: '',
            email: '',
            voter_registration: '',
        };

        let hasError = false;

        if (!formData.name) {
            hasError = true;
            errors.name = 'O nome é obrigatório';
        }
        // if (!formData.cpf) {
        //     hasError = true;
        //     errors.cpf = 'O CPF é obrigatório';
        // }
        if (!formData.phone) {
            hasError = true;
            errors.phone = 'O telefone é obrigatório';
        }
        if (!formData.birth_date) {
            hasError = true;
            errors.birth_date = 'A data de nascimento é obrigatória';
        }
        if (!formData.mother_name) {
            hasError = true;
            errors.mother_name = 'O nome da mãe é obrigatório';
        }
        if (!formData.address) {
            hasError = true;
            errors.address = 'O endereço é obrigatório';
        }
        if (!formData.zip_code) {
            hasError = true;
            errors.zip_code = 'O CEP é obrigatório';
        }

        setFormValidationMessages(errors);

        return !hasError;
    }

    document.title = "Cadastro de Eleitor | Geld";
    const [phone, setPhone] = useState("");

    const [selectedSingle, setSelectedSingle] = useState(null);

    function onPhoneChange(e: { target: { rawValue: React.SetStateAction<string>; }; }) {
        setPhone(e.target.rawValue);
    }

    const handleRatingChange = (index: number) => {
        setRating(index);
        setFormData({ ...formData, number_classification: index });
        // Perform actions with the selected rating
        // You can submit the form or update the state of a parent component with the rating value
        // For example: onSubmit(index);
    };

    const StarRating = () => {

        var divStyle = {
            fontSize: '25px !important'
        };
        return (
            <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                    index += 1;

                    return (
                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "on " : "off"}
                            onClick={() => handleRatingChange(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <span className="star" style={divStyle}>&#9733;</span>
                        </button>
                    );
                })}
            </div>
        );
    };

    function handleClickUseTermsLink(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        toggleModalUseTerms();
    }

    function toggleModalUseTerms() {
        setModalUseTermsIsOpen((prevState) => !prevState);
    }

    return (
        <React.Fragment>
            <Modal isOpen={modalUseTermsIsOpen} toggle={toggleModalUseTerms}>
                <ModalHeader toggle={toggleModalUseTerms}>Termos de Uso de Dados</ModalHeader>
                <ModalBody>
                    <p>
                        Ao assinar o presente, concordo que <b>Leandro Dourado</b> e sua equipe de assessoria, doravante denominada <b>Controlador</b>,
                        {' '} tenham acesso aos meus dados aqui coletados, podendo realizar tratamento desses dados pessoais,
                        {' '} e tão somente estes, no âmbito da reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação
                        {' '} ou controle da informação, comunicação, transferência, difusão ou extração, sempre observados os ditames da <b>LGPD nº 13.709/2018</b>.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleModalUseTerms}>
                        Fechar
                    </Button>
                </ModalFooter>
            </Modal>

            <div className="page-content pt-1">
                <div className="d-flex justify-content-center pt-3 pb-3 w-100">
                    <img src="/logo.png" style={{ height: "80px" }} />
                </div>

                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Novo Eleitor</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <span className="text-danger">* Campos obrigatórios</span>
                                            </Col>
                                        </Row>


                                        {!!userIdLinkOwner && (
                                            <>
                                                <Row>
                                                    <Col lg={4}></Col>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                        <FilePond
                                                            className='filepond filepond-container filepond-photo'
                                                            files={files}
                                                            onupdatefiles={(fileItems) => setFiles([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                            allowReorder={true}
                                                            allowMultiple={false}
                                                            labelIdle={`
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <img src="/icons/upload.png" />
                                                            <b style="color: #888">Arraste ou clique para upload da foto</b>
                                                        </div>
                                                    `}
                                                            id="fileUpload"
                                                            name="fileUpload"
                                                            acceptedFileTypes={['image/*']} // Restrict to image files
                                                            stylePanelAspectRatio={(300 / 440).toString()} // Convert the aspect ratio to a string
                                                        />
                                                    </Col>
                                                    <Col lg={4}></Col>
                                                </Row>

                                                <Row className="mb-5">
                                                    <Col lg={4}>
                                                        <div>
                                                            <label htmlFor="created_by_description" className="form-label">
                                                                Cadastrado  por (Responsável)
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                className="form-control mb-3"
                                                                id="created_by_description"
                                                                placeholder="Nome do responsável"
                                                                name="created_by_description"
                                                                value={formData.created_by_description}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <div>
                                                            <label htmlFor="handleGroupChange" className="form-label">Grupo</label>
                                                            <select className="form-select mb-3" id="handleGroupChange" aria-label="Default select example"
                                                                name="group"
                                                                value={formData.group_id}
                                                                onChange={handleGroupChange}>
                                                                <option value="">Selecione um Grupo</option>
                                                                {optionsGroupId.map((option) => (
                                                                    <option key={option.id} value={option.id}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}

                                        <Row className="mb-3">
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="cpf" className="form-label">
                                                        CPF
                                                    </label>
                                                    <Cleave
                                                        placeholder="Buscar pelo CPF"
                                                        options={{
                                                            delimiters: ['.', '.', '-'],
                                                            blocks: [3, 3, 3, 2],
                                                            numericOnly: true,
                                                        }}
                                                        className={`form-control ${!!formValidationMessages.cpf ? 'is-invalid' : ''}`}
                                                        id="cpf"
                                                        name="cpf"
                                                        value={formData.cpf}
                                                        onChange={handleChangeCpf}
                                                    />
                                                    {!!formValidationMessages.cpf && (
                                                        <FormFeedback>
                                                            {formValidationMessages.cpf}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="inputName" className="form-label">
                                                        Nome <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputName"
                                                        placeholder="Digite seu Nome"
                                                        invalid={!!formValidationMessages.name}
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                    />
                                                    {!!formValidationMessages.name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.name}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="cleave-phone" className="form-label">
                                                        Telefone <span className="text-danger">*</span>
                                                    </label>
                                                    <Cleave
                                                        placeholder="xx xxxxx xxxx"
                                                        options={{
                                                            delimiter: ' ',
                                                            blocks: [2, 5, 4] // [2 digits, 5 digits, 4 digits]
                                                        }}
                                                        className={`form-control ${!!formValidationMessages.phone ? 'is-invalid' : ''}`}
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                    />
                                                    {!!formValidationMessages.phone && (
                                                        <FormFeedback>
                                                            {formValidationMessages.phone}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="birth_date" className="form-label">
                                                        Data de Nascimento <span className="text-danger">*</span>
                                                    </label>
                                                    <Flatpickr
                                                        id="birth_date"
                                                        name="birth_date"
                                                        className={`form-control ${!!formValidationMessages.birth_date ? 'is-invalid' : ''}`}
                                                        placeholder="Selecione uma data"
                                                        options={{
                                                            dateFormat: "d/m/Y",
                                                            locale: flatpickrLocale,
                                                            allowInput: true,
                                                        }}
                                                        onChange={(dates, currentDateString) => handleBirthDateChange(currentDateString)}
                                                        value={formData.birth_date}
                                                    />
                                                    {!!formValidationMessages.birth_date && (
                                                        <FormFeedback>
                                                            {formValidationMessages.birth_date}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="mother_name" className="form-label">
                                                        Nome da mãe <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="mother_name"
                                                        placeholder="Digite o nome"
                                                        name="mother_name"
                                                        value={formData.mother_name}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.mother_name}
                                                    />
                                                    {!!formValidationMessages.mother_name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.mother_name}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>

                                            {!!userIdLinkOwner && (
                                                <>
                                                    <Col lg={3} className="mt-3">
                                                        <div>
                                                            <label htmlFor="voter_registration" className="form-label">Título de eleitor</label>
                                                            <Input
                                                                type="text" className="form-control mb-3"
                                                                id="voter_registration"
                                                                placeholder="Digite seu Titulo Eleitoral"
                                                                name="voter_registration"
                                                                value={formData.voter_registration}
                                                                onChange={handleChange}
                                                                invalid={!!formValidationMessages.voter_registration}
                                                            />
                                                            {!!formValidationMessages.voter_registration && (
                                                                <FormFeedback>
                                                                    {formValidationMessages.voter_registration}
                                                                </FormFeedback>
                                                            )}
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} className="mt-3">
                                                        <div>
                                                            <label htmlFor="electoral_ward_id" className="form-label">Zona Eleitoral</label>
                                                            <input type="text" className="form-control mb-3" id="electoral_ward_id" placeholder="Digite sua Zona"
                                                                name="electoral_ward_id"
                                                                value={formData.electoral_ward_id}
                                                                onChange={handleChange} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} className="mt-3">
                                                        <div>
                                                            <label htmlFor="section" className="form-label">Seção</label>
                                                            <input type="text" className="form-control mb-3" id="section" placeholder="Digite sua Referência"
                                                                name="section"
                                                                value={formData.section}
                                                                onChange={handleChange} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <div>
                                                            <label htmlFor="school" className="form-label">Escola</label>
                                                            <input type="text" className="form-control" id="school" placeholder="Digite sua Escola"
                                                                name="school"
                                                                value={formData.school}
                                                                onChange={handleChange} />
                                                        </div>
                                                    </Col>
                                                </>
                                            )}
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="zip_code" className="form-label">
                                                        CEP <span className="text-danger">*</span>
                                                    </label>
                                                    <Cleave
                                                        placeholder="Buscar CEP"
                                                        options={{
                                                            delimiter: '-',
                                                            blocks: [5, 3],
                                                            numericOnly: true,
                                                        }}
                                                        className="form-control mb-3"
                                                        id="zip_code"
                                                        name="zip_code"
                                                        value={formData.zip_code}
                                                        onChange={handleChangeCep}
                                                    />
                                                    {!!formValidationMessages.zip_code && (
                                                        <FormFeedback>
                                                            {formValidationMessages.zip_code}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6} className="mt-3">
                                                <div>
                                                    <label htmlFor="address" className="form-label">
                                                        Endereço <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.address}
                                                    />
                                                    {!!formValidationMessages.address && (
                                                        <FormFeedback>
                                                            {formValidationMessages.address}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="number" className="form-label">Número</label>
                                                    <input type="number" className="form-control mb-3" id="number"
                                                        name="number"
                                                        value={formData.number}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="address_complement" className="form-label">Complemento</label>
                                                    <input type="text" className="form-control mb-3" id="address_complement"
                                                        name="address_complement"
                                                        value={formData.address_complement}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="neighborhood" className="form-label">Bairro</label>
                                                    <input type="text" className="form-control mb-3" id="neighborhood"
                                                        name="neighborhood"
                                                        value={formData.neighborhood}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="city" className="form-label">
                                                        Cidade 
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control mb-3"
                                                        id="city"
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="state" className="form-label">
                                                        Estado (UF)
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control mb-3"
                                                        id="state"
                                                        name="state"
                                                        value={formData.state}
                                                        onChange={handleChange}
                                                        maxLength={2}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault"
                                                        checked={useTermsAccepted}
                                                        onChange={(e) => setUseTermsAccepted(e.target.checked)}
                                                    />
                                                    <b className="">
                                                        Ao clicar no botão Cadastrar, eu li e aceito os <a href="#" onClick={handleClickUseTermsLink}>Termos de Uso de Dados</a>.
                                                        <br />
                                                        {' '} Antes de prosseguir, é essencial que você leia os <a href="#" onClick={handleClickUseTermsLink}>Termos de Uso de Dados</a> para entender como suas informações serão tratadas.
                                                    </b>
                                                </div>
                                            </Col>
                                        </Row>

                                        <button type="submit" className="btn btn-primary waves-effect waves-light mt-2" disabled={!useTermsAccepted}>Cadastrar</button>
                                    </Form>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
}
