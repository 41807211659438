import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form, Input, FormFeedback } from 'reactstrap';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';


import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "./filepondCustom.css";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Swal from 'sweetalert2';
import { api } from "../../utils/api";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


export function NewUsers() {
    const navigate = useNavigate();
    const [optionsGroupId, setOptionsGroupId] = useState<Option[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [isVotersChecked, setVotersChecked] = useState(false);
    const [isComunicationChecked, setComunicationChecked] = useState(false);
    const [isServiceChecked, setServiceChecked] = useState(false);
    const [isParametersChecked, setParametersChecked] = useState(false);

    type Option = {
        id: number;
        name: string;
    };

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        user_type: '',
        password: '',
        photo: '',
        switchVotersAccess: '',
        voterDashboardShortcut: 'N',
        switchComunicationAccess: '',
        communicationDashboardShortcut: 'N',
        switchServiceAccess: '',
        serviceDashboardShortcut: 'N',
        switchParametersUserAccess: '',
        switchParametersSelectFieldAccess: '',
        password_confirm: ''
    });
    const [formValidationMessages, setFormValidationMessages] = useState({
        name: '',
        email: '',
        user_type: '',
        password: '',
        password_confirm: ''
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
    };

    const handleCheckPermission = (field: string, value: string, checked: boolean) => {
        setFormData((prevState) => ({ ...prevState, [field]: checked ? value : 'none' }));
    }

    const handleCheckDashboardPermission = (field: string, checked: boolean) => {
        setFormData((prevState) => ({ ...prevState, [field]: checked ? 'Y' : 'N' }));
    }

    const handleUserTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, user_type: event.target.value });
    };
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const responseGroupSelect = await api.get('/getGroup');
            setOptionsGroupId(responseGroupSelect.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const isValid = validateFormData();
        if (!isValid) {
            Swal.fire('Erro', `Verifique os erros do formulário`, 'error');
            return false;
        }

        var formFile = new FormData();
        if (files.length) {
            formFile.append('image', files[0], files[0].name);
        }
        Object.entries(formData).forEach(([key, value]) => {
            formFile.append(key, String(value));
        });
        api.post('/createUser', formFile)
            .then(response => {
                navigate('/usuários');
            })
            .catch(error => {
                // Handle the error response
                console.error(error);
                Swal.fire('Erro', 'Falha na criação de usuário!', 'error');

            });
    };

    function validateFormData() {
        const errors = {
            name: '',
            email: '',
            user_type: '',
            password: '',
            password_confirm: ''
        };

        let hasError = false;

        if (!formData.name) {
            hasError = true;
            errors.name = 'O nome é obrigatório';
        }
        if (!formData.email) {
            hasError = true;
            errors.email = 'O email é obrigatório';
        }
        if (!formData.user_type) {
            hasError = true;
            errors.user_type = 'O tipo de usuário é obrigatório';
        }
        if (!formData.password) {
            hasError = true;
            errors.password = 'A senha é obrigatória';
        }
        if (!formData.password_confirm) {
            hasError = true;
            errors.password_confirm = 'A confirmação da senha é obrigatória';
        }
        if (formData.password !== formData.password_confirm) {
            hasError = true;
            errors.password = 'As senhas não coincidem';
            errors.password_confirm = 'As senhas não coincidem';
        }

        setFormValidationMessages(errors);

        return !hasError;
    }

    const handleVotersSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVotersChecked(event.target.checked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            switchVotersAccess: event.target.checked ? prevFormData.switchVotersAccess : '',
            voterDashboardShortcut: event.target.checked ? prevFormData.voterDashboardShortcut : 'N',
        }));
    };

    const handleComunicationSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComunicationChecked(event.target.checked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            switchComunicationAccess: event.target.checked ? prevFormData.switchComunicationAccess : '',
            communicationDashboardShortcut: event.target.checked ? prevFormData.communicationDashboardShortcut : 'N',
        }));
    };
    const handleServiceSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceChecked(event.target.checked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            switchServiceAccess: event.target.checked ? prevFormData.switchServiceAccess : '',
            serviceDashboardShortcut: event.target.checked ? prevFormData.serviceDashboardShortcut : 'N',
        }));
    };
    const handleParametersSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParametersChecked(event.target.checked);
    };

    console.log(formData);

    document.title = "Usuários | Geld";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Novo Usuário</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <span className="text-danger">* Campos obrigatórios</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}></Col>
                                            <Col lg={4} className="d-flex justify-content-center">
                                                <FilePond
                                                    className='filepond filepond-container filepond-photo'
                                                    files={files}
                                                    onupdatefiles={(fileItems) => setFiles([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                    allowReorder={true}
                                                    allowMultiple={false}
                                                    labelIdle={`
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <img src="/icons/upload.png" />
                                                            <b style="color: #888">Arraste ou clique para upload da foto</b>
                                                        </div>
                                                    `}
                                                    id="fileUpload"
                                                    name="fileUpload"
                                                    acceptedFileTypes={['image/*']} // Restrict to image files
                                                    stylePanelAspectRatio={(300 / 440).toString()} // Convert the aspect ratio to a string
                                                />
                                            </Col>
                                            <Col lg={4}></Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="inputName" className="form-label">
                                                        Nome <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputName"
                                                        placeholder="Digite seu Nome"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.name}
                                                    />
                                                    {!!formValidationMessages.name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.name}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="inputEmail" className="form-label">
                                                        Email <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail"
                                                        placeholder="exemplo@exemplo.com"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.email}
                                                    />
                                                    {!!formValidationMessages.email && (
                                                        <FormFeedback>
                                                            {formValidationMessages.email}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>

                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="cleave-phone" className="form-label">Telefone</label>
                                                    <Cleave placeholder="xx xxxx-xxxx"
                                                        options={{
                                                            phone: true,
                                                            phoneRegionCode: "br"
                                                        }}
                                                        className="form-control"
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="choices-single-default" className="form-label text-muted">
                                                        Tipo de Usuário <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className={`form-select ${!!formValidationMessages.user_type ? 'is-invalid' : ''}`}
                                                        aria-label="Default select example"
                                                        name="user_type"
                                                        value={formData.user_type}
                                                        onChange={handleUserTypeChange}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="administrator">Administrador</option>
                                                        <option value="user">Usuário</option>
                                                    </select>
                                                    {!!formValidationMessages.user_type && (
                                                        <FormFeedback>
                                                            {formValidationMessages.user_type}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="password" className="form-label">
                                                        Senha <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        placeholder="Digite sua Senha"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.password}
                                                    />
                                                    {!!formValidationMessages.password && (
                                                        <FormFeedback>
                                                            {formValidationMessages.password}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="password_confirm" className="form-label">
                                                        Confirmar Senha <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="password"
                                                        className="form-control"
                                                        id="password_confirm"
                                                        placeholder="Confirme sua senha"
                                                        name="password_confirm"
                                                        value={formData.password_confirm}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.password_confirm}
                                                    />
                                                    {!!formValidationMessages.password_confirm && (
                                                        <FormFeedback>
                                                            {formValidationMessages.password_confirm}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Card className="mt-4">
                                            <CardHeader style={{ backgroundColor: '#f1f1f1' }}>
                                                <Row>
                                                    <h5 className="card-title">Permissões de Usuário</h5>
                                                    <div className="rounded-circle p-1" style={{ backgroundColor: '#FFFFFF' }} ></div>
                                                </Row>
                                            </CardHeader>
                                            <CardBody style={{ backgroundColor: '#f1f1f1' }}>
                                                <Row>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                        <Row>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <div className="form-check form-switch form-switch-secondary">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchVoters" name="switchVoters" onChange={handleVotersSwitchChange} />
                                                                    <label className="form-check-label" htmlFor="switchVoters">Eleitores</label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <Row>
                                                                    {/* Render the additional div conditionally */}
                                                                    {isVotersChecked && (
                                                                        <>
                                                                            <Col lg={12}>
                                                                                <div className="">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchVotersAccess"
                                                                                        id="switchVotersAccessview"
                                                                                        value="view"
                                                                                        onChange={(e) => handleCheckPermission('switchVotersAccess', 'view', e.target.checked)}
                                                                                        checked={formData.switchVotersAccess === 'view'}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Visualizar ou Visualizar/Editar </label>
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchVotersAccess"
                                                                                        id="switchVotersAccessEdit"
                                                                                        value="edit"
                                                                                        onChange={(e) => handleCheckPermission('switchVotersAccess', 'edit', e.target.checked)}
                                                                                        checked={formData.switchVotersAccess === 'edit'}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <div className="d-flex justify-content-center mt-2">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="voterDashboardShortcut"
                                                                                        id="voterDashboardShortcutCheck"
                                                                                        onChange={(e) => handleCheckDashboardPermission('voterDashboardShortcut', e.target.checked)}
                                                                                        checked={formData.voterDashboardShortcut === 'Y'}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Atalho dashboard </label>
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                        <Row>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <div className="form-check form-switch form-switch-secondary">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchComunication" onChange={handleComunicationSwitchChange} />
                                                                    <label className="form-check-label" htmlFor="switchComunication">Comunicação</label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <Row>
                                                                    {/* Render the additional div conditionally */}
                                                                    {isComunicationChecked && (
                                                                        <>
                                                                            <Col lg={12}>
                                                                                <div className="">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchComunicationAccess"
                                                                                        id="switchComunicationAccess"
                                                                                        value="view"
                                                                                        onChange={(e) => handleCheckPermission('switchComunicationAccess', 'view', e.target.checked)}
                                                                                        checked={formData.switchComunicationAccess === 'view'}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Visualizar ou Visualizar/Editar </label>
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchComunicationAccess"
                                                                                        id="switchComunicationAccess"
                                                                                        value="edit"
                                                                                        onChange={(e) => handleCheckPermission('switchComunicationAccess', 'edit', e.target.checked)}
                                                                                        checked={formData.switchComunicationAccess === 'edit'}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <div className="d-flex justify-content-center mt-2">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="communicationDashboardShortcut"
                                                                                        id="communicationDashboardShortcutCheck"
                                                                                        onChange={(e) => handleCheckDashboardPermission('communicationDashboardShortcut', e.target.checked)}
                                                                                        checked={formData.communicationDashboardShortcut === 'Y'}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Atalho dashboard </label>
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                        <Row>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <div className="form-check form-switch form-switch-secondary">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchService" onChange={handleServiceSwitchChange} />
                                                                    <label className="form-check-label" htmlFor="switchService">Demanda</label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <Row>
                                                                    {/* Render the additional div conditionally */}
                                                                    {isServiceChecked && (
                                                                        <>
                                                                            <Col lg={12}>
                                                                                <div className="">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchServiceAccess"
                                                                                        id="switchServiceAccess"
                                                                                        value="view"
                                                                                        onChange={(e) => handleCheckPermission('switchServiceAccess', 'view', e.target.checked)}
                                                                                        checked={formData.switchServiceAccess === 'view'}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Visualizar ou Visualizar/Editar </label>
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchServiceAccess"
                                                                                        id="switchServiceAccess"
                                                                                        value="edit"
                                                                                        onChange={(e) => handleCheckPermission('switchServiceAccess', 'edit', e.target.checked)}
                                                                                        checked={formData.switchServiceAccess === 'edit'}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <div className="d-flex justify-content-center mt-2">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="serviceDashboardShortcut"
                                                                                        id="serviceDashboardShortcutCheck"
                                                                                        onChange={(e) => handleCheckDashboardPermission('serviceDashboardShortcut', e.target.checked)}
                                                                                        checked={formData.serviceDashboardShortcut === 'Y'}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Atalho dashboard </label>
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-5">
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                    </Col>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                        <Row>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <div className="form-check form-switch form-switch-secondary">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchParameters" onChange={handleParametersSwitchChange} />
                                                                    <label className="form-check-label" htmlFor="switchParameters">Parâmetros</label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12}>
                                                                {isParametersChecked && (
                                                                    <Row>
                                                                        <Col lg={4} className="d-flex justify-content-center">Visualizar</Col>
                                                                        <Col lg={4}></Col>
                                                                        <Col lg={4} className="d-flex justify-content-center">Editar</Col>

                                                                        <Col lg={4} className="d-flex justify-content-center"><input className="form-check-input" type="radio" name="switchParametersUserAccess" id="switchParametersUserAccess" value="view" onChange={handleChange} /></Col>
                                                                        <Col lg={4}>Usuários</Col>
                                                                        <Col lg={4} className="d-flex justify-content-center"><input className="form-check-input" type="radio" name="switchParametersUserAccess" id="switchParametersUserAccess" value="edit" onChange={handleChange} /></Col>

                                                                        <Col lg={4} className="d-flex justify-content-center"><input className="form-check-input" type="radio" name="switchParametersSelectFieldAccess" id="switchParametersSelectFieldAccess" value="view" onChange={handleChange} /></Col>
                                                                        <Col lg={4}>Grupos</Col>
                                                                        <Col lg={4} className="d-flex justify-content-center"><input className="form-check-input" type="radio" name="switchParametersSelectFieldAccess" id="switchParametersSelectFieldAccess" value="edit" onChange={handleChange} /></Col>
                                                                    </Row>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <button type="submit" className="btn btn-primary waves-effect waves-light">Salvar</button>
                                    </Form>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
