import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import Swal from "sweetalert2";

export default function CopyVoterRegisterLinkButton({ label }: { label: string }) {
    const handleClickCopyLink = (linkType: 'public' | 'user') => {
        let url = `${process.env.REACT_APP_PUBLIC_URL}/registro-Eleitor-link`;

        if (linkType === 'user') {
            const authUser = JSON.parse(localStorage.getItem("authUser") ?? '{}');
            const userId = Number(authUser.id || 0);

            if (!userId) {
                Swal.fire('Erro', 'Falha ao copiar o link!', 'error');
                return;
            }

            url = `${process.env.REACT_APP_PUBLIC_URL}/registro-Eleitor-link?user=${userId}`;
        }

        navigator.clipboard.writeText(url)
            .then(() => {
                Swal.fire('Sucesso', 'Link copiado com sucesso!', 'success');

            })
            .catch((error) => {
                console.error('Falha ao copiar o link:', error);
                Swal.fire('Erro', 'Falha ao copiar o link!', 'error');
            });
    };

    return (
        <UncontrolledButtonDropdown direction="down">
            <DropdownToggle className="btn btn-primary" caret>{label}</DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => handleClickCopyLink('public')}>Link público</DropdownItem>
                <DropdownItem onClick={() => handleClickCopyLink('user')}>Seu link de cadastro</DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
}