import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Button, Card, Container, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Progress, Row, UncontrolledDropdown, Spinner } from 'reactstrap';

import { Link, useParams } from "react-router-dom";
import CountUp from "react-countup";

//Import Icons
import FeatherIcon from "feather-icons-react";
// Import Components
import UsersByDevice from './UsersByDevice';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import TopReferrals from './TopReferrals';
import TopPages from './TopPages';
import './dashboard.css';
import { api } from "../../../utils/api";
import voterIcon from "../../assets/images/iconVoters.png";
import { MyPortfolioCharts } from './WidgetsCharts';
import { widgetsAudiences, widgetsPortfolio, } from "../../common/data/index";
import btcCoin from "../../assets/images/svg/crypto-icons/btc.svg";
import ltcCoin from "../../assets/images/svg/crypto-icons/ltc.svg";
import ethCoin from "../../assets/images/svg/crypto-icons/eth.svg";
import dashCoin from "../../assets/images/svg/crypto-icons/dash.svg";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";
import CopyVoterRegisterLinkButton from '../../../components/CopyVoterRegisterLinkButton';

const DashboardAnalytics = () => {
    document.title = "Dashboard | Geld";

    const [votersCount, setVotersCount] = useState(0);
    const [communicationCount, setCommunicationCount] = useState(0);
    const [demandsCount, setDemandsCount] = useState(0);
    const [votersInGroup, setVotersInGroup] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupColors, setGroupColors] = useState([]);
    const [countOccurrenceDemand, setCountOccurrenceDemand] = useState([]);
    const [countVotersPerMonth, setCountVotersPerMonth] = useState([]);
    const [months, setMonths] = useState([]);

    const [isExportingPdf, setIsExportingPdf] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const votersCountResponse = await api.get('/getDashboradCountVoter');
                const communicationsCountResponse = await api.get('/getDashboradCountCommunication');
                const demandsCountResponse = await api.get('/getDashboradCountDemmand');
                setVotersCount(votersCountResponse.allVoters);
                setCommunicationCount(communicationsCountResponse.allCommunications);
                setDemandsCount(demandsCountResponse.allDemmands);

                const responseAccess = await api.get(`/getAuthIdAccessEdit`);
                const permissions = responseAccess.permissions;

                setTiles((prevTiles) =>
                    prevTiles.map((item) => {
                        switch (item.id) {
                            case 1:
                                return {
                                    ...item,
                                    subCounter: [
                                        {
                                            id: 1,
                                            counter: votersCountResponse.allVoters,
                                        },
                                    ],
                                    visible: permissions.voters?.dashboard_create_shortcut === 'Y',
                                };
                            case 2:
                                return {
                                    ...item,
                                    subCounter: [
                                        {
                                            id: 1,
                                            counter: communicationsCountResponse.allCommunications,
                                        },
                                    ],
                                    visible: permissions.communication?.dashboard_create_shortcut === 'Y',
                                };
                            case 3:
                                return {
                                    ...item,
                                    subCounter: [
                                        {
                                            id: 1,
                                            counter: demandsCountResponse.allDemmands,
                                        },
                                    ],
                                    visible: permissions.services?.dashboard_create_shortcut === 'Y',
                                };
                        }
                        return item;
                    })
                );
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        groupVoters();
        occurrenceDemand();
        votersPerMonth();
    }, []);
    const [tiles, setTiles] = useState([
        {
            id: 1,
            bgColor: "bg-white",
            label: "Eleitores",
            labelClass: "black-50",
            counterClass: "text-black",
            badgeClass: "bg-light text-success",
            badge: "ri-arrow-up-line",
            percentage: "16.24 %",
            iconClass: "info",
            feaIcon: "las la-user-friends",
            iconSrc: "icons/icon-voter.png",
            route: "/registro-Eleitor",
            buttonName: "Criar Eleitor",
            subCounter: [
                {
                    id: 1,
                    counter: votersCount,
                },
            ],
            visible: false,
        },
        {
            id: 2,
            bgColor: "bg-white",
            label: "Envios",
            labelClass: "black-50",
            counterClass: "text-black",
            badgeClass: "bg-light text-danger",
            badge: "bx bx-conversation",
            percentage: "3.96 %",
            iconClass: "info",
            feaIcon: "bx bx-conversation",
            iconSrc: "icons/icon-sends.png",
            route: "/novo-envio",
            buttonName: "Criar Envios",
            subCounter: [
                {
                    id: 1,
                    counter: communicationCount,
                },
            ],
            visible: false,
        },
        {
            id: 3,
            bgColor: "bg-white",
            label: "Demandas",
            labelClass: "black-50",
            counterClass: "text-black",
            badgeClass: "badge-soft-light",
            badge: "ri-file-list-line",
            percentage: "0.24 %",
            iconClass: "info",
            feaIcon: "ri-file-list-line",
            iconSrc: "icons/icon-demmand.png",
            route: "/registro-demanda",
            buttonName: "Criar Demanda",
            subCounter: [
                {
                    id: 1,
                    counter: demandsCount,
                },
            ],
            visible: false,
        },
    ]);
    const widgetsPortfolio = [
        {
            id: 1,
            icon: 'ri-file-list-line',
            label: "Pendentes",
            href: "/demanda?search=pending",
            badgeClass: "primary",
            priceClass: "primary",
            coinNameClass: "primary"
        },
        {
            id: 2,
            icon: 'ri-file-list-line',
            label: "Concluidos",
            href: "/demanda?search=delivered",
            badgeClass: "success",
            priceClass: "success",
            coinNameClass: "success"
        },
        {
            id: 3,
            icon: 'ri-file-list-line',
            label: "Atrasados",
            href: "/demanda?search=late",
            badgeClass: "danger",
            priceClass: "danger",
            coinNameClass: "danger"
        },
    ];
    const groupVoters = async () => {
        const responseGroups = await api.get('/getGroup');

        var data = [];
        var groups = [];
        var colors = [];

        for (var index = 0; index < responseGroups.data.length; index++) {
            const countVoters = await api.get(`/getCountVotersInGroup/${responseGroups.data[index].id}`);
            data.push(countVoters.count ?? 0);
            groups.push(responseGroups.data[index].name ?? '');
            colors.push(responseGroups.data[index].color ?? '');
        }

        setVotersInGroup(data);
        setGroups(groups);
        setGroupColors(colors);
    };

    const occurrenceDemand = async () => {
        const demmands = await api.get('/getOccurrenceDemmand');
        setCountOccurrenceDemand([demmands.data.pending, demmands.data.concluded, demmands.data.late]);
    };


    const votersPerMonth = async () => {
        const response = await api.get('/getVotersPerMonth');
        setCountVotersPerMonth(response.data.countVoters);
        setMonths(response.data.months);
    };


    const handleButtonClick = async () => {
        try {
            setIsExportingPdf(true);

            const response = await api.get('/dashboard/reportPdf', { responseType: 'arraybuffer' });

            var pdfDataBlob = new Blob([response], { type: 'application/pdf' });

            saveAs(pdfDataBlob, `Relatório.pdf`);

        } catch (error) {
            console.error('Error generating PDF:', error);
        }

        setIsExportingPdf(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className='mb-2'>
                        <Col>
                            <button className="btn rounded-pill btn-primary waves-effect waves-light" onClick={handleButtonClick}>
                                Imprimir Relatório
                                {isExportingPdf && (
                                    <Spinner size="sm" style={{ marginLeft: 6 }} />
                                )}
                            </button>
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-end">
                                <CopyVoterRegisterLinkButton label="Link de Cadastro de Eleitor" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {(tiles || []).map((item, key) => (
                            <Col lg={4} key={key}>
                                <Card className={"card-animate " + item.bgColor} style={{ height: '90%' }}>
                                    <CardBody>
                                        <div className="d-flex align-items-center justify-content-around">
                                            <div className="avatar-lg flex-shrink-0">
                                                <img src={item.iconSrc} height={90} />
                                            </div>
                                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <p className={"fw-medium mb-0 text-" + item.labelClass}>{item.label}</p>
                                                <h2 className={"mt-4 ff-secondary fw-semibold " + item.counterClass}>
                                                    {item.subCounter.map((item, key) => (<span className="counter-value" key={key}>
                                                        <CountUp
                                                            start={0}
                                                            prefix={item.prefix}
                                                            suffix={item.suffix}
                                                            separator={item.separator}
                                                            end={item.counter}
                                                            decimals={item.decimals}
                                                            duration={4}
                                                        />
                                                    </span>))}
                                                </h2>
                                                {item.visible && (
                                                    <Link to={item.route} className='d-flex justify-content-center'>
                                                        <button className="btn btn-lg rounded-pill btn-primary waves-effect waves-light button1 px-5">{item.buttonName}</button>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row className='d-flex justify-content-around'>
                        <Col lg={6}>
                            <Card className="card-height-100">
                                <CardHeader>
                                    <h4 className="card-title mb-0">Eleitores por grupo</h4>
                                </CardHeader>
                                <CardBody>
                                    <ReactApexChart
                                        dir="ltr"
                                        className="apex-charts"
                                        series={[{
                                            data: votersInGroup
                                        }]}
                                        options={{
                                            chart: {
                                                type: 'bar',
                                                height: 350,
                                                toolbar: {
                                                    show: false,
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    barHeight: '100%',
                                                    distributed: true,
                                                    horizontal: true,
                                                    dataLabels: {
                                                        position: 'bottom'
                                                    },
                                                }
                                            },
                                            colors: groupColors,
                                            dataLabels: {
                                                enabled: true,
                                                textAnchor: 'start',
                                                style: {
                                                    colors: ['#fff']
                                                },
                                                formatter: function (val, opt) {
                                                    return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
                                                },
                                                offsetX: 0,
                                                dropShadow: {
                                                    enabled: false
                                                }
                                            },
                                            stroke: {
                                                width: 1,
                                                colors: ['#fff']
                                            },
                                            xaxis: {
                                                categories: groups,
                                            },
                                            yaxis: {
                                                labels: {
                                                    show: false
                                                }
                                            },
                                            subtitle: {
                                                text: 'Número de eleitores por grupo',
                                                align: 'center',
                                            },
                                            tooltip: {
                                                theme: 'light',
                                                x: {
                                                    show: false
                                                },
                                                y: {
                                                    title: {
                                                        formatter: function () {
                                                            return '';
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                        type="bar"
                                        height={350}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card className="card-height-100">
                                <CardHeader className="border-bottom-dashed align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Demada de ocorrencia</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="portfolio_donut_charts" dir="ltr">
                                        {/* My Portfolio Chart */}
                                        <ReactApexChart
                                            dir="ltr"
                                            className="apex-charts"
                                            series={countOccurrenceDemand}
                                            options={{
                                                labels: ["Pendentes", "Concluidos", "Atrasados"],
                                                chart: {
                                                    type: "donut",
                                                    height: 224,
                                                },

                                                plotOptions: {
                                                    pie: {
                                                        size: 100,
                                                        offsetX: 0,
                                                        offsetY: 0,
                                                        donut: {
                                                            size: "70%",
                                                            labels: {
                                                                show: true,
                                                                name: {
                                                                    show: true,
                                                                    fontSize: '18px',
                                                                    offsetY: -5,
                                                                },
                                                                value: {
                                                                    show: true,
                                                                    fontSize: '20px',
                                                                    color: '#FFF',
                                                                    fontWeight: 500,
                                                                    offsetY: 5,
                                                                    formatter: function (val) {
                                                                        return val;
                                                                    }
                                                                },
                                                                total: {
                                                                    show: true,
                                                                    fontSize: '13px',
                                                                    label: 'Demandas totais',
                                                                    color: '#9599ad',
                                                                    fontWeight: 500,
                                                                    formatter: function (w) {
                                                                        return w.globals.seriesTotals.reduce(function (a, b) {
                                                                            return a + b;
                                                                        }, 0);
                                                                    }
                                                                }
                                                            }
                                                        },
                                                    },
                                                },
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                                legend: {
                                                    show: false,
                                                },
                                                yaxis: {
                                                    labels: {
                                                        formatter: function (value) {
                                                            return value;
                                                        }
                                                    }
                                                },
                                                stroke: {
                                                    lineCap: "round",
                                                    width: 2
                                                },
                                                colors: ['#5ea3cb', '#6ada7d', "#fa896b"],
                                            }}
                                            type="donut"
                                            height="210"
                                        />
                                    </div>

                                    <ListGroup className="border-dashed mb-0" flush>


                                        {(widgetsPortfolio || []).map((item, key) => (
                                            <ListGroupItem className="px-0" key={key}>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <span className="avatar-title bg-light p-1 rounded-circle">
                                                            <i className={item.icon} />
                                                        </span>
                                                    </div>
                                                    <a href={item.href} className="flex-grow-1 ms-2">
                                                        <h6 className="mb-1">{item.label}</h6>
                                                        <p className="fs-12 mb-0 text-muted"><i className={"mdi mdi-circle fs-10 align-middle me-1 text-" + item.coinNameClass}></i>{item.coinName}</p>
                                                    </a>
                                                    <div className="flex-shrink-0 text-end">
                                                        <h6 className="mb-1">{item.coinName} {item.coinVolume}</h6>
                                                        <p className={"fs-12 mb-0 text-" + item.priceClass}>{countOccurrenceDemand[item.id - 1]}</p>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Eleitores por mes</h4>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <ReactApexChart
                                            dir="ltr"
                                            series={[{
                                                name: 'Eleitores',
                                                data: countVotersPerMonth
                                            }]}
                                            options={{
                                                chart: {
                                                    height: 350,
                                                    type: 'bar',
                                                    toolbar: {
                                                        show: false
                                                    },
                                                    defaultLocale: "pt-br",
                                                    locales: [
                                                        {
                                                            name: 'pt-br',
                                                            options: {
                                                                months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                                                                shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                                                            }
                                                        }
                                                    ]
                                                },
                                                stroke: {
                                                    width: 7,
                                                    curve: 'smooth'
                                                },
                                                xaxis: {
                                                    type: 'datetime',
                                                    categories: months,
                                                    tickAmount: 10,
                                                },
                                                title: {
                                                    text: 'Números de Eleitores cadastrados esse mes',
                                                    align: 'left',
                                                    style: {
                                                        fontWeight: 500,
                                                    },
                                                },
                                                fill: {
                                                    colors: "#0025be",
                                                    type: 'solid',
                                                },
                                                markers: {
                                                    size: 4,
                                                    colors: ['#0f0'],
                                                    strokeColors: "#fff",
                                                    strokeWidth: 2,
                                                    hover: {
                                                        size: 7,
                                                    }
                                                },
                                                // yaxis: {
                                                //     min: 0,
                                                //     max: 300,
                                                //     title: {
                                                //         text: 'Engagement',
                                                //     },
                                                // }
                                            }}
                                            type="bar"
                                            height={350}
                                            className="apex-charts"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardAnalytics;
