import { GenericObject } from "../types/GenericObject";

/**
 * Retorna true caso alguma das propriedades do objeto passado tiver o valor truthy
 */
export function objectPropertiesHaveValue(object: GenericObject) {
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            const element = object[key];
            
            if (element) {
                return true;
            }
        }
    }

    return false;
}