import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Spinner, UncontrolledDropdown } from 'reactstrap';
import { api } from '../../utils/api';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from "react-router-dom";

import CustomPagination from "../../components/customPagination.js"

const BaseExampleVoters = ({
  tableData,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
  total,
  isLoading,
  handleSort,
}) => {
  const tableRef = useRef(null);

  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>Nome</span>,
      selector: row => row.nome,
      sortable: true,
      cell: (row) => <div style={{ whiteSpace: 'normal' }}>{row.nome}</div>,
    },
    {
      name: <span className='font-weight-bold fs-13'>Data de Nascimento</span>,
      selector: row => row.birth_date,
      sortable: true,
      cell: (row) => <div style={{ whiteSpace: 'normal' }}>{row.birth_date}</div>,
    },
    {
      name: <span className='font-weight-bold fs-13'>Grupo</span>,
      selector: row => row.cargo,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Telefone</span>,
      selector: row => row.telefone,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Bairro</span>,
      selector: row => row.bairro,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'></span>,
      sortable: false,
      cell: (row, index) => {
        return (
          <i
            className={`ri-check-line ${row.last_send_success && row.last_send_success === 'Y' ? '' : 'hide-icon'}`}
          ></i>
        );
      },
    },
  ];

  return (
    <>
      <div ref={tableRef}>
        <DataTable columns={columns} data={tableData} pagination
          onSort={handleSort}
          paginationServer
          sortServer
          defaultSortFieldId={1}
          defaultSortAsc
          progressPending={isLoading}
          progressComponent={<Spinner />}
          paginationTotalRows={total}
          paginationPerPage={rowsPerPage}
          paginationComponent={CustomPagination}
          noDataComponent={<div>Nenhum registro encontrado</div>}
          paginationComponentOptions={{
            rowsPerPageText: 'Dados por Pagina:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        >
        </DataTable>
      </div>
    </>
  );
};

export default BaseExampleVoters;
