import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardAnalytics from "./_velzon/pages/DashboardAnalytics";

// //login
import Login from "./_velzon/pages/Authentication/Login";
import ForgetPasswordPage from "./_velzon/pages/Authentication/ForgetPassword";
import Logout from "./_velzon/pages/Authentication/Logout";
import Register from "./_velzon/pages/Authentication/Register";

// User Profile
import UserProfile from "./_velzon/pages/Authentication/user-profile";
import { Voters } from "./pages/Voters/Voters";
import { NewVoters } from "./pages/Voters/VotersNew";
import { NewVotersLink } from "./pages/Voters/VotersNewLink";
import { EditVoters } from "./pages/Voters/VotersEdit";

import { Users } from "./pages/Users/Users";
import { NewUsers } from "./pages/Users/UsersNew";
import { EditUsers } from "./pages/Users/UsersEdit";

import { Services } from "./pages/Services/Services";
import { NewServices } from "./pages/Services/ServicesNew";
import { EditServices } from "./pages/Services/ServicesEdit";

import { Communications } from "./pages/Communication/Communication";
import { NewCommunications } from "./pages/Communication/NewCommunications";
import { CommunicationEdit } from "./pages/Communication/CommunicationEdit";

import { SelectField } from "./pages/Groups/SelectFields";
import { VoterBirthDateMessage } from "./pages/VoterBirthDateMessage";

const authProtectedRoutes = [
  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <DashboardAnalytics />,
  },
  { path: "/eleitores", exact: true, component: <Voters />, },
  { path: "/registro-Eleitor", exact: true, component: <NewVoters /> },
  { path: "/editar-eleitor/:voterId", exact: true, component: <EditVoters /> },

  { path: "/usuários", exact: true, component: <Users />, },
  { path: "/registro-usuário", exact: true, component: <NewUsers /> },
  { path: "/editar-usuário/:userId", exact: true, component: <EditUsers /> },

  { path: "/comunicacao", exact: true, component: <Communications />, },
  { path: "/novo-envio", exact: true, component: <NewCommunications /> },
  { path: "/editar-comunicacao/:communicationId", exact: true, component: <CommunicationEdit /> },

  { path: "/demanda", exact: true, component: <Services />, },
  { path: "/registro-demanda", exact: true, component: <NewServices /> },
  { path: "/editar-demanda/:demmandId", exact: true, component: <EditServices /> },

  { path: "/campos-de-selecao", exact: true, component: <SelectField />, },

  { path: "/envio-de-aniversario", exact: true, component: <VoterBirthDateMessage /> },

  // { path: "*", component: <Navigate to="/" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/registro-Eleitor-link", exact: true, component: <NewVotersLink /> },

  /* { path: "/eleitores", component: <Voters /> }, */
];

export { authProtectedRoutes, publicRoutes };