import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Spinner, UncontrolledDropdown } from 'reactstrap';
import { api } from '../../utils/api';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from "react-router-dom";
import CustomPagination from "../../components/customPagination.js"

const BaseExampleVoters = ({tableData , rowsPerPage, handlePageChange, handleRowsPerPageChange,total,isLoading}) => {
  const tableRef = useRef(null);
  
  const handleDelete = async (voterId) => {

    const responseVoter = await api.get(`/getVoter/${voterId}/delete`);

    console.log('Delete row:', rowIndex);
    window.location.reload();

  };
  const handleEdit = (rowIndex) => {
    console.log('Edit row:', rowIndex);
  };

  const handleExportExcel = () => {
    const excelData = tableData.map((item) => ({
      Nome: item.nome,
      Cargo: item.cargo,
      Telefone: item.telefone,
      Bairro: item.bairro,
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelDataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelDataBlob, 'Eleitores.xlsx');
  };

  const handleExportPDF = () => {
    const table = tableRef.current;

    html2canvas(table).then((canvas) => {
      const tableImage = canvas.toDataURL('image/png');
      const pdfWidth = table.offsetWidth;
      const pdfHeight = table.offsetHeight;

      const doc = new jsPDF({
        orientation: 'l',
        unit: 'px',
        format: [pdfWidth, pdfHeight],
      });

      doc.addImage(tableImage, 'PNG', 0, 0, pdfWidth, pdfHeight);
      doc.save('Eleitores.pdf');
    });
  };

  const handleFormSubmit = async (formData) => {
    try {
      // Send form data to the server
      await api.post('/submitForm', formData);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const columns = [
    {
        name: <span className='font-weight-bold fs-13'>Nome</span>,
        selector: row => row.name,
        sortable: false
    },
    {
      name: <span className='font-weight-bold fs-13'>Data de Atualização</span>,
      selector: row => row.created_at,
      sortable: false
    },
    {
      name: <span className='font-weight-bold fs-13'>Comentário</span>,
      selector: row => row.commentary,
        sortable: false
    },
    {
      name: <span className='font-weight-bold fs-13'>status</span>,
      sortable: false,
      selector: (cell) => {
          switch (cell.status) {
              case 'delivered':
                  return <span className="badge badge-soft-info"> Entregue</span>;
              case 'late':
                  return <span className="badge badge-soft-secondary"> Atrasado</span>;
              case 'canceled':
                  return <span className="badge badge-soft-danger"> Cancelado</span>;
              case 'pending':
                  return <span className="badge badge-soft-danger"> Pendente</span>;
              default:
                  return <span className="badge badge-soft-success">  </span>;
          }
      },
    },
];
const customButtonStyle = {
  marginRight: '10px',
};
  return (
    <>
      <div ref={tableRef}>
      <DataTable columns={columns} data={tableData} pagination
        paginationServer
        progressPending = {isLoading}
        progressComponent={<Spinner />}
        paginationTotalRows={total}
        paginationPerPage={rowsPerPage}        
        paginationComponent={CustomPagination}
        paginationComponentOptions={{
          rowsPerPageText: 'Dados por Pagina:',
          rangeSeparatorText: 'de',
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: 'All',
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        noDataComponent={<div>Nenhum registro encontrado</div>}

      >
      </DataTable>
      </div>
    </>
  );
};

/* const ServerSideExample = ({tableDataSearch }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get('/getCommunications'); // Replace with your server-side endpoint
      setTableData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return <BaseExample tableData={tableData} />;
}; */

export default BaseExampleVoters;
