import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Spinner, UncontrolledDropdown, Row } from 'reactstrap';
import { api } from '../../utils/api';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from "react-router-dom";
import CustomPagination from "../../components/customPagination.js"
const BaseExample = ({
  tableData,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
  total,
  isLoading,
  handleSort,
  onExportExcel,
  onExportPdf,
  showVoters = true,
  canDelete = true,
}) => {
  const tableRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [hasEditPermission, setHasEditPermission] = useState(false);
  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [userLogged, setUserLogged] = useState({
    active: "",
    cell: "",
    created_at: "",
    email: "",
    email_verified_at: null,
    id: 1,
    name: "",
    photo: "",
    updated_at: "",
    user_type: ""
  });
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    fetchData();
    setFilteredData(tableData); // Initialize filteredData with the original tableData
    GetPermissionsEdit();

  }, [tableData]);

  const fetchData = async () => {
    try {
        const responseUser = await api.get(`/getUserLogged`); // pega usuario logado
        setUserLogged(responseUser.data ?? null)
        setIsAdmin(userLogged.user_type == "administrator") // verifica se é administrador
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  
  const GetPermissionsEdit = async () => {
    const responseAccess = await api.get(`/getAuthIdAccessEdit`);
    if (responseAccess.permissions.services.access_type === "edit") {
      setHasEditPermission(true);
    } else {
      setHasEditPermission(false);
    }
  }
  const handleDelete = async (voterId) => {

    const responseVoter = await api.get(`/getDemmand/${voterId}/delete`);

    window.location.reload();
  };
  const handleEdit = (rowIndex) => {
    console.log('Edit row:', rowIndex);
  };

  const handleExportExcel = async () => {
    setIsExportingExcel(true);

    if (onExportExcel) {
      await onExportExcel();
    }

    setIsExportingExcel(false);
  };

  const handleExportPDF = async () => {
    setIsExportingPdf(true);

    if (onExportPdf) {
      await onExportPdf();
    }

    setIsExportingPdf(false);
  };

  const handleFormSubmit = async (formData) => {
    try {
      // Send form data to the server
      await api.post('/submitForm', formData);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const renderVoterColumnCondition = () => {
    return showVoters ? [{
      name: <span className='font-weight-bold fs-13'>Eleitor</span>,
      selector: row => row.voter,
      sortable: true
    }] : [];
  };

  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>Id</span>,
      selector: row => row.id,
      sortable: true
    },
    ...renderVoterColumnCondition(),
    {
      name: <span className='font-weight-bold fs-13'>Categoria</span>,
      selector: row => row.category,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Responsável</span>,
      selector: row => row.responsible,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Data de Regis.</span>,
      selector: row => row.open_date,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Data Final</span>,
      selector: row => row.venc_date,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Prioridade</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.priority) {
          case 'high':
            return <span className="badge bg-danger">Alta</span>;
          case 'average':
            return <span className="badge bg-warning">Média</span>;
          case 'low':
            return <span className="badge bg-info"> Baixa</span>;
          default:
            return <span>  </span>;
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>status</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.status) {
          case 'delivered':
            return <span className="badge bg-success"> Entregue</span>;
          case 'late':
            return <span className="badge bg-danger"> Atrasado</span>;
          case 'canceled':
            return <span className="badge bg-primary"> Cancelado</span>;
          case 'pending':
            return <span className="badge bg-warning"> Pendente</span>;
          default:
            return <span>  </span>;
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Ação</span>,
      sortable: true,

      cell: (row) => {
        const { id } = row;
        if (hasEditPermission) {
          return (
            <UncontrolledDropdown className="dropdown d-inline-block">
              <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu container='body' className="dropdown-menu-end">
                <DropdownItem className='edit-item-btn'><Link to={`/editar-demanda/${id}`}><i className="ri-edit-2-line"></i>Editar</Link></DropdownItem>
                {canDelete && (
                  <DropdownItem className='remove-item-btn'><a onClick={() => handleDelete(id)}><i className="ri-delete-bin-line"></i>Deletar</a></DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        } else {
          return null; // Return null if the user doesn't have edit permission
        }
      },
    },
  ];

  const customButtonStyle = {
    marginRight: '10px',
  };

  const handleFilter = (e) => {
    var searchValue = e.target.value.toLowerCase();
    if (searchValue == "entregue") {
      var searchValue = 'delivered';
    }
    if (searchValue == "atrasado") {
      var searchValue = 'late';
    }
    if (searchValue == "cancelado") {
      var searchValue = 'canceled';
    }
    if (searchValue == "pendente") {
      var searchValue = 'pending';
    }
    if (searchValue == "alta") {
      var searchValue = 'high';
    }
    if (searchValue == "media") {
      var searchValue = 'average';
    }
    if (searchValue == "baixa") {
      var searchValue = 'low';
    }
    const filteredTableData = tableData.filter((item) => {
      for (const key in item) {
        if (item[key] && item[key].toString().toLowerCase().includes(searchValue)) {
          return true;
        }
      }
      return false;
    });
    setFilteredData(filteredTableData);
  };
  const exportButtons = onExportExcel && onExportPdf && isAdmin ? (
    <Row>
      <div className='d-flex justify-content-between'>
        <div>
          <button className="btn btn-primary btn-animation waves-effect waves-light" onClick={handleExportExcel} style={customButtonStyle}>
            Excel
            {isExportingExcel && (
              <Spinner size="sm" style={{ marginLeft: 6 }} />
            )}
          </button>
          <button className="btn btn-primary btn-animation waves-effect waves-light" onClick={handleExportPDF} style={customButtonStyle}>
            PDF
            {isExportingPdf && (
              <Spinner size="sm" style={{ marginLeft: 6 }} />
            )}
          </button>
        </div>
        <div className="d-flex align-items-center">
          <i className="ri-search-line p-2"></i> <Input type="text" placeholder="Buscar" onChange={handleFilter} />
        </div>
      </div>
    </Row>
  ) : null;

  return (
    <>
      {exportButtons}
      <div ref={tableRef}>
        <DataTable columns={columns} data={filteredData} pagination
          onSort={handleSort}
          paginationServer
          sortServer
          defaultSortFieldId={1}
          defaultSortAsc={false}
          noDataComponent={<div>Nenhum registro encontrado</div>}
          progressPending={isLoading}
          progressComponent={<Spinner />}
          paginationTotalRows={total}
          paginationPerPage={rowsPerPage}
          paginationComponent={CustomPagination}
          paginationComponentOptions={{
            rowsPerPageText: 'Dados por Pagina:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        >
        </DataTable>
      </div>
    </>
  );
};

/* const ServerSideExample = ({tableDataSearch }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get('/getCommunications'); // Replace with your server-side endpoint
      setTableData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return <BaseExample tableData={tableData} />;
}; */

export default BaseExample;
