import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form } from 'reactstrap';
import { Link, useParams, useSearchParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';

import makeAnimated from "react-select/animated";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const animatedComponents = makeAnimated();

const SingleOptions = [
    { value: 'Choices 1', label: 'Choices 1' },
    { value: 'Choices 2', label: 'Choices 2' },
    { value: 'Choices 3', label: 'Choices 3' },
    { value: 'Choices 4', label: 'Choices 4' }
];

//import COmponents
import BaseExample from './GridTablesData';
import { api } from "../../utils/api";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getDemmandPriorityTextFormatted } from "../../utils/getDemmandPriorityTextFormatted";
import { getDemmandStatusTextFormated } from "../../utils/getDemmandStatusTextFormated";

export function Services() {
    document.title = "Demandas | Geld";

    const [queryParameters] = useSearchParams();

    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    //const [optionsGroupId, setOptionsGroupId] = useState([]);
    const [optionsGroupId, setOptionsGroupId] = useState<Option[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    const [selectSortRow, setselectSortRow] = useState('id');
    const [sortDirection, setsortDirection] = useState('desc');
    const [hasEdit, sethasEdit] = useState(false);

    type Option = {
        id: number;
        name: string;
    };
    const [formData, setFormData] = useState({
        neighborhood: '',
        electoral_zone: '',
        group: '',
        reference: '',
        education: '',
        address: '',
        age: '',
        number_classification: '',
        profession: '',
        created_by: '',
        gender: '',
        otherGender: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleGroupChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, group: event.target.value });
    };
    const handleNumberClassificationChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, number_classification: event.target.value });
    };
    const handleCreatedByChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, created_by: event.target.value });
    };
    const handleSubmitSearch = (e: FormEvent) => {
        e.preventDefault();

        api.post('/searchVoters', formData)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    useEffect(() => {
        fetchData();
    }, [currentPage, rowsPerPage, selectSortRow, sortDirection]);
    const fetchData = async () => {
        try {
            const responseAccess = await api.get(`/getAuthIdAccessEdit`);

            if (!responseAccess.permissions.services || !responseAccess.permissions.services.access_type) {
                window.location.href = '/';
                return;
            }

            if (responseAccess.permissions.services.access_type == "edit") {
                sethasEdit(true);
            }

            setisLoading(true);
            const response = await api.get('/getDemmand', {
                params: {
                    page: currentPage, // Pass the current page directly
                    perPage: rowsPerPage, // Pass the number of rows per page
                    selectSortRow: selectSortRow, // Pass the number of rows per page
                    sortDirection: sortDirection, // Pass the number of rows per page,
                    status: queryParameters.get("search")
                },
            });
            const responseData = response;

            setData(response.data.data);
            setTotalRows(response.data.totalCount);
            setisLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(!isVisible);
    };
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setCurrentPage(1);
        setRowsPerPage(newRowsPerPage);
    };
    const handleSort = (selectSortRow: any, sortDirection: string) => {
        switch (selectSortRow.id) {
            case 1:
                setselectSortRow('id')
                break;
            case 2:
                setselectSortRow('voter')
                break;
            case 3:
                setselectSortRow('category')
                break;
            case 4:
                setselectSortRow('responsible')
                break;
            case 5:
                setselectSortRow('open_date')
                break;
            case 6:
                setselectSortRow('venc_date')
                break;
            case 7:
                setselectSortRow('priority')
                break;
            case 8:
                setselectSortRow('status')
                break;
            default:
                break;
        }
        setsortDirection(sortDirection)
    }

    const handleExportExcel = async () => {
        const exportData = data;

        if (exportData.length === 0) {
            exportData.push({
                'Nome': '',
                'Data de Envio': '',
                'Recorrência': '',
                'Enviado por': '',
            })
        }

        const excelData = exportData.map((item) => ({
            'Eleitor': item.voter,
            'Categoria': item.category,
            'Responsável': item.responsible,
            'Data de Regis.': item.open_date,
            'Data Final': item.venc_date,
            'Prioridade': getDemmandPriorityTextFormatted(item.priority),
            'Status': getDemmandStatusTextFormated(item.status),
        }));

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const columnWidths = [
            { wch: 40 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
        ];
        worksheet['!cols'] = columnWidths;

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelDataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelDataBlob, 'Demandas.xlsx');
    };

    const handleExportPdf = async () => {
        let response: any;

        response = await api.get('/getDemmand/pdf', {
            params: {
                selectSortRow: selectSortRow,
                sortDirection: sortDirection,
            },
            responseType: 'arraybuffer'
        });

        var pdfDataBlob = new Blob([response], { type: 'application/pdf' });

        saveAs(pdfDataBlob, 'Demandas.pdf');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Col>
                                        <div className="d-flex justify-content-start">
                                            <h4 className="card-title mb-0 flex-grow-1">Demanda</h4>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex justify-content-center">
                                            {hasEdit && (
                                                <Link to="/registro-demanda">
                                                    <button type="button" className="btn btn-primary btn-animation waves-effect waves-light" data-text="Registrar Demanda"><span className="d-flex flex-row align-items-center">Nova Demanda <i className="la la-lg la-plus-square-o" style={{ marginLeft: "5px" }}></i></span></button>
                                                </Link>
                                            )}
                                        </div>
                                    </Col>
                                    <Col>
                                    </Col>
                                </CardHeader>
                                <CardBody>
                                    <BaseExample tableData={data}
                                        rowsPerPage={rowsPerPage}
                                        handlePageChange={handlePageChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        total={totalRows}
                                        isLoading={isLoading}
                                        handleSort={handleSort}
                                        onExportExcel={handleExportExcel}
                                        onExportPdf={handleExportPdf}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
{/* <CardBody>
    <BaseExample tableData ={data}/>
</CardBody> */}