import React from 'react';
import ReactDOM from 'react-dom';

import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.pt';
//import Scss
import './_velzon/assets/scss/themes.scss';
import "./App.css";

//imoprt Route
import Route from './_velzon/Routes';

import Header from './_velzon/Layouts/Header';
import Footer from './_velzon/Layouts/Footer';
import VerticalLayout from './_velzon/Layouts/index';

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
