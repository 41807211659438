import React, { useEffect,useState, ChangeEvent, FormEvent } from "react";
import { isEmpty } from "lodash";

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import ReactDOM from 'react-dom';
import TestService from "../../../services/TestService";
import Modal from 'react-bootstrap/Modal';
import { Link,useParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';

import Select from "react-select";
import makeAnimated from "react-select/animated";
import filePondInput from "../../../components/filePond";
import {GroupId} from "../../../components/select2Datas";
import Swal from 'sweetalert2';

import { api } from "../../../utils/api";
import {FilePond} from "react-filepond";
import {registerPlugin} from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const UserProfile = () => {
  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [idx, setidx] = useState("");
  const [files, setFiles] = useState([]);
  const [responseImage, setResponseImage] = useState("Admin");

  const [userName, setUserName] = useState("Admin");

  const { user, success, error } = useSelector(state => ({
    user: state.Profile.user,
    success: state.Profile.success,
    error: state.Profile.error
  }));
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    photo: '',
});
const handleChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
};
  useEffect(() => {
    fetchData();
    if (localStorage.getItem("authUser")) {
      const user = JSON.parse(localStorage.getItem("authUser"));
      setUserName(user?.name ?? 'Admin');
    }
  }, [dispatch, user]);

  const fetchData = async () => {
    try {
      const responseUser = await api.get(`/getUserLogged`);
      //setFormData(responseVoter.data);
      setFormData({ 
          name: responseUser.data.name ?? '' ,
          phone: responseUser.data.cell ?? '' ,

      })
      setidx(responseUser.data.id);
      setemail(responseUser.data.email);
      console.log(responseUser.data.photo);
      if(responseUser.data.photo){
        const response = await fetch(process.env.REACT_APP_API_URL+'getImage/user/'+responseUser.data.photo ?? '');
        const data = await response.blob();
        const convertedFile = new File([data], responseUser.data.photo ?? '', { type: data.type });
        setFiles([convertedFile]);
        setResponseImage(process.env.REACT_APP_API_URL+'getImage/user/'+responseUser.data.photo);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    var formFile = new FormData();
    if(files.length){
        formFile.append('image', files[0], files[0].name);
    }
    Object.entries(formData).forEach(([key, value]) => {
      formFile.append(key, String(value));
    });
    api.post(`/editUserLogged`, formFile)
      .then(response => {
        // Handle the success response
        //navigate('/eleitores');
        if (response.data) {
          const authUser = JSON.parse(localStorage.getItem("authUser"));
          localStorage.setItem("authUser", JSON.stringify({...response.data, token: authUser.token}));
        }

        Swal.fire('Sucesso', 'Usuário Atualizado com Sucesso!', 'success').then(() => {
          window.location.reload();
        });
      })
      .catch(error => {
        // Handle the error response
        console.error(error);
        Swal.fire('Erro', 'Falha na atualização de usuário!', 'error');

      });
  };
    
  document.title = "Meu Perfil | Geld";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={responseImage}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userName || "Admin"}</h5>
                        <p className="mb-1">Email : {email}</p>
                        <p className="mb-0">Id: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Alterar dados do usuário</h4>

          <Card>
            <CardBody>
              <Form
                onSubmit={handleEdit}
                className="form-horizontal">
                <Row>
                  <Col lg={3}>
                      <div>
                          <label htmlFor="inputName" className="form-label">Nome</label>
                          <input type="text" className="form-control" id="inputName" placeholder="Digite seu Nome"
                          name = "name"
                          value={formData.name}
                          onChange={handleChange} required/>
                      </div>
                  </Col>
                  <Col lg={3}>
                      <div>
                            <label htmlFor="cleave-phone" className="form-label">Telefone</label>
                          <Cleave placeholder="xx xxxx-xxxx"
                          options={{
                              phone: true,
                              phoneRegionCode: "br"
                          }}
                          className="form-control"
                          name = "phone"
                          value={formData.phone}
                          onChange={handleChange}
                          />
                      </div>
                  </Col>
                  <Col lg={3}>
                      <div>
                          <label htmlFor="password" className="form-label">Senha</label>
                          <input type="text" className="form-control" id="password" placeholder="Digite sua Senha"
                          name = "password"
                          value={formData.password}
                          onChange={handleChange}/>
                      </div>
                  </Col>
                  <Col lg={3}>
                    <FilePond
                        className='filepond--custom--class'
                        files={files}
                        onupdatefiles={(fileItems) => setFiles([...fileItems.map((fileItem) => fileItem.file)])}
                        allowReorder={true}
                        allowMultiple={false}
                        labelIdle={`
                            <div class="d-flex flex-column align-items-center justify-content-center">
                                <img src="/icons/upload.png" />
                                <b style="color: #888">Arraste ou clique para upload da foto</b>
                            </div>
                        `}
                        id="fileUpload"
                        name="fileUpload"
                        acceptedFileTypes={['image/*']} // Restrict to image files
                        stylePanelLayout='compact circle'
                        styleLoadIndicatorPosition='center bottom'
                        styleProgressIndicatorPosition='right bottom'
                        styleButtonRemoveItemPosition='left bottom'
                        styleButtonProcessItemPosition='right bottom'
                    />
                  </Col>
                </Row>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Atualizar dados
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
