export function getDemmandPriorityTextFormatted(priority: string) {
    switch (priority) {
        case 'high':
            return 'Alta';
        case 'average':
            return 'Média';
        case 'low':
            return 'Baixa';
        default:
            return '';
    };
}