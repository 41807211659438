import React, { useEffect, useState, useRef } from 'react';
import DataTable, { Pagination } from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Spinner, UncontrolledDropdown, Row } from 'reactstrap';
import { api } from '../../utils/api';
import 'jspdf-autotable';
import { Link } from "react-router-dom";
import CustomPagination from "../../components/customPagination.js"
const BaseExample = ({
  tableData,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
  total,
  isLoading,
  handleSort,
  onExportExcel,
  onExportPdf,
  setSearchValue
}) => {
  const tableRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [hasEditPermission, setHasEditPermission] = useState(false);
  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [userLogged, setUserLogged] = useState({
    active: "",
    cell: "",
    created_at: "",
    email: "",
    email_verified_at: null,
    id: 1,
    name: "",
    photo: "",
    updated_at: "",
    user_type: ""
  });
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    fetchData()
    setFilteredData(tableData); // Initialize filteredData with the original tableData
    GetPermissionsEdit();
  }, [tableData]);
  const GetPermissionsEdit = async () => {
    const responseAccess = await api.get(`/getAuthIdAccessEdit`);
    if (responseAccess.permissions.voters.access_type === "edit") {
      setHasEditPermission(true);
    } else {
      setHasEditPermission(false);
    }
  }

  const fetchData = async () => {
    try {
        const responseUser = await api.get(`/getUserLogged`); // pega usuario logado
        setUserLogged(responseUser.data ?? null)
        setIsAdmin(userLogged.user_type == "administrator") // verifica se é administrador
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  
  const handleDelete = async (voterId) => {

    const responseVoter = await api.get(`/getVoter/${voterId}/delete`);

    window.location.reload();

  };
  const handleEdit = (rowIndex) => {
    console.log('Edit row:', rowIndex);
  };

  const handleExportExcel = async () => {
    setIsExportingExcel(true);

    await onExportExcel();

    setIsExportingExcel(false);
  };

  const handleExportPDF = async() => {
    setIsExportingPdf(true);

    await onExportPdf();

    setIsExportingPdf(false);
  };

  const handleFormSubmit = async (formData) => {
    try {
      // Send form data to the server
      await api.post('/submitForm', formData);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>ID</span>,
      selector: row => row.id,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Nome</span>,
      selector: row => row.nome,
      sortable: true,
      cell: (row) => <div style={{ whiteSpace: 'normal' }}>{row.nome}</div>,
    },
    {
      name: <span className='font-weight-bold fs-13'>Grupo</span>,
      selector: row => row.cargo,
      sortable: false
    },
    {
      name: <span className='font-weight-bold fs-13'>Progresso</span>,
      selector: row => row.progress,
      sortable: false
    },
    {
      name: <span className='font-weight-bold fs-13'>Telefone</span>,
      selector: row => row.telefone,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Bairro</span>,
      selector: row => row.bairro,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Ação</span>,
      sortable: true,

      cell: (row) => {
        const { id } = row;
        if (hasEditPermission) {
          return (
            <UncontrolledDropdown className="dropdown d-inline-block">
              <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu container='body' className="dropdown-menu-end">
                <DropdownItem className='edit-item-btn'><Link target="_blank" to={`/editar-eleitor/${id}`}><i className="ri-edit-2-line"></i>Editar</Link></DropdownItem>
                <DropdownItem className='remove-item-btn'><a onClick={() => handleDelete(id)}><i className="ri-delete-bin-line"></i>Deletar</a></DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        } else {
          return null; // Return null if the user doesn't have edit permission
        }
      },
    },
  ];
  const customButtonStyle = {
    marginRight: '10px',
  };
  const handleFilter = (e) => {
    // var searchValue = e.target.value.toLowerCase();
    // const filteredTableData = tableData.filter((item) => {
    //   for (const key in item) {
    //     if (item[key] && item[key].toString().toLowerCase().includes(searchValue)) {
    //       return true;
    //     }
    //   }
    //   return false;
    // });
    setSearchValue(e.target.value.toLowerCase());
  };

  const exportButtons = (
    isAdmin ? (
      <Row>
        <div className='d-flex justify-content-between'>
          <div>
            <button className="btn btn-primary btn-animation waves-effect waves-light" onClick={handleExportExcel} style={customButtonStyle}>
              Excel
              {isExportingExcel && (
                <Spinner size="sm" style={{ marginLeft: 6 }} />
              )}
            </button>
            <button className="btn btn-primary btn-animation waves-effect waves-light" onClick={handleExportPDF} style={customButtonStyle}>
              PDF
              {isExportingPdf && (
                <Spinner size="sm" style={{ marginLeft: 6 }} />
              )}
            </button>
          </div>
          <div className="d-flex align-items-center">
            <i className="ri-search-line p-2"></i> <Input type="text" placeholder="Buscar" onKeyUp={handleFilter} />
          </div>
        </div>
      </Row>
    ) : null
  );
  return (
    <>
      {exportButtons}
      <div ref={tableRef}>
        <DataTable columns={columns} data={filteredData} pagination
          onSort={handleSort}
          paginationServer
          sortServer
          defaultSortFieldId={2}
          defaultSortAsc
          noDataComponent={<div>Nenhum registro encontrado</div>}
          progressPending={isLoading}
          progressComponent={<Spinner />}
          paginationTotalRows={total}
          paginationPerPage={rowsPerPage}
          paginationComponent={CustomPagination}
          paginationComponentOptions={{
            rowsPerPageText: 'Dados por Pagina:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
          onChangePage={handlePageChange}
          totalRecords={total}
          onChangeRowsPerPage={handleRowsPerPageChange}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}

        >
        </DataTable>
      </div>
    </>
  );
};

export default BaseExample;
