import { useEffect, useState } from "react";
import { getLoggedinUser } from "../../helpers/api_helper";

const useProfile = () => {
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    async function setLoggedUserInfo() {
      const userProfileSession = await getLoggedinUser();

      setUserProfile(userProfileSession ? userProfileSession : null);
      
      setLoading(false);
    }

    setLoggedUserInfo();
  }, []);


  return { userProfile, loading, token: userProfile?.token ?? null };
};

export { useProfile };