import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form } from 'reactstrap';
import { Link, useParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';

import makeAnimated from "react-select/animated";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const animatedComponents = makeAnimated();

const SingleOptions = [
    { value: 'Choices 1', label: 'Choices 1' },
    { value: 'Choices 2', label: 'Choices 2' },
    { value: 'Choices 3', label: 'Choices 3' },
    { value: 'Choices 4', label: 'Choices 4' }
];

//import COmponents
import BaseExample from './GridTablesData';
import { api } from "../../utils/api";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getCommunicationFrequencyTextFormatted } from "../../utils/getCommunicationFrequency";

export function Communications() {
    document.title = "Comunicação | Geld";

    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    //const [optionsGroupId, setOptionsGroupId] = useState([]);
    const [optionsGroupId, setOptionsGroupId] = useState<Option[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    const [selectSortRow, setselectSortRow] = useState('id');
    const [sortDirection, setsortDirection] = useState('desc');

    type Option = {
        id: number;
        name: string;
    };
    useEffect(() => {
        fetchData();
    }, [currentPage, rowsPerPage, selectSortRow, sortDirection]);
    const fetchData = async () => {
        try {
            setisLoading(true);

            const responseAccess = await api.get(`/getAuthIdAccessEdit`);

            if (!responseAccess.permissions.communication || !responseAccess.permissions.communication.access_type) {
                window.location.href = '/';
                return;
            }

            const response = await api.get('/getCommunications', {
                params: {
                    page: currentPage, // Pass the current page directly
                    perPage: rowsPerPage, // Pass the number of rows per page
                    selectSortRow: selectSortRow, // Pass the number of rows per page
                    sortDirection: sortDirection, // Pass the number of rows per page
                },
            });
            const responseData = response;
            setTotalRows(responseData.data.totalCount);

            setData(responseData.data.data);
            setisLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setCurrentPage(1);
        setRowsPerPage(newRowsPerPage);
    };
    const handleSort = (selectSortRow: any, sortDirection: string) => {
        switch (selectSortRow.id) {
            case 1:
                setselectSortRow('id')
                break;
            case 2:
                setselectSortRow('name')
                break;
            case 3:
                setselectSortRow('send_date')
                break;
            case 4:
                setselectSortRow('frequency')
                break;
            case 5:
                setselectSortRow('created_by')
                break;
            case 6:
                setselectSortRow('status')
                break;
            default:
                break;
        }
        setsortDirection(sortDirection)
    }

    const handleExportExcel = async () => {
        const exportData = data;

        if (exportData.length === 0) {
            exportData.push({
                'Nome': '',
                'Data de Envio': '',
                'Recorrência': '',
                'Enviado por': '',
            })
        }

        const excelData = exportData.map((item) => ({
            'Nome': item.name,
            'Data de Envio': item.send_date,
            'Recorrência': getCommunicationFrequencyTextFormatted(item.frequency),
            'Enviado por': item.created_by,
        }));

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const columnWidths = [
            { wch: 30 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
        ];
        worksheet['!cols'] = columnWidths;

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelDataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelDataBlob, 'Comunicação.xlsx');
    };

    const handleExportPdf = async () => {
        let response: any;

        response = await api.get('/getCommunications/pdf', {
            params: {
                selectSortRow: selectSortRow,
                sortDirection: sortDirection,
            },
            responseType: 'arraybuffer'
        });

        var pdfDataBlob = new Blob([response], { type: 'application/pdf' });

        saveAs(pdfDataBlob, 'Comunicação.pdf');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col lg={3} md={6}>
                                            <div className="d-flex justify-content-start">
                                                <h4 className="card-title mb-0 flex-grow-1">Comunicação</h4>
                                            </div>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <Link to="/novo-envio" style={{ marginRight: 12 }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-animation waves-effect waves-light"
                                                    data-text="Novo
                                                Envio">
                                                    <span className="d-flex flex-row align-items-center">Novo Envio <i className="la la-lg la-plus-square-o" style={{ marginLeft: "5px" }}></i></span>
                                                </button>
                                            </Link>
                                            <Link to="/envio-de-aniversario">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-animation waves-effect waves-light"
                                                    data-text="Envio de Aniversário">
                                                    <span className="d-flex flex-row align-items-center">Envio de Aniversário <i className="la la-lg la-birthday-cake" style={{ marginLeft: "5px" }}></i></span>
                                                </button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <BaseExample tableData={data}
                                        rowsPerPage={10}
                                        handlePageChange={handlePageChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        total={totalRows}
                                        isLoading={isLoading}
                                        handleSort={handleSort}
                                        onExportExcel={handleExportExcel}
                                        onExportPdf={handleExportPdf}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
{/* <CardBody>
    <BaseExample tableData ={data}/>
</CardBody> */}