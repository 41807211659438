import React, { useEffect,useState, ChangeEvent, FormEvent } from "react";
import ReactDOM from 'react-dom';
import TestService from "../../services/TestService";
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Link,useParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';

import Select from "react-select";
import makeAnimated from "react-select/animated";
import filePondInput from "../../components/filePond";
import {GroupId} from "../../components/select2Datas";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Swal from 'sweetalert2';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const animatedComponents = makeAnimated();

const SingleOptions = [
    { value: 'Choices 1', label: 'Choices 1' },
    { value: 'Choices 2', label: 'Choices 2' },
    { value: 'Choices 3', label: 'Choices 3' },
    { value: 'Choices 4', label: 'Choices 4' }
];

//import COmponents
import BaseExample  from './GridTablesData';
import { TesteExport } from '../../components/phoneInputMask';
import { api } from "../../utils/api";
import { FilePondFile } from "filepond";

export function SelectField(){
    const [showOtherGender, setShowOtherGender] = useState(false);
    const [otherGender, setOtherGender] = useState('');
    const navigate = useNavigate();
    const [optionsGroupId, setOptionsGroupId] = useState<Option[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [selectedType, setSelectedType] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [inputs, setInputs] = useState<JSX.Element[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalInputValue, setModalInputValue] = useState("");

    type Option = {
        id: number;
        name: string;
        active: boolean;
    };
    type Item = {
        active: string;
        id: number;
        name: string;
        created_at: string | null;
        updated_at: string | null;
    };
    type FormData ={
        array: Option[];
    }
    
    const [formData, setFormData] = useState<FormData>({
        array: []
    });
    const [formDataModal, setFormDataModal] = useState({
        name:''
    });
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,index:number) => {
        const arrayValue = formData.array;
        var arrayValueIndex = arrayValue[index];
        arrayValue[index] = {id:arrayValueIndex.id,name:e.target.value.toUpperCase(),active:arrayValueIndex.active};
        setFormData({ ...formData, array: [...arrayValue] });
    };
    const handleChangeStatus = (index:number, active:boolean,) => {
        setFormData({ ...formData, array: [] });

        const arrayValue = formData.array;
        var arrayValueIndex = arrayValue[index];
        arrayValue[index] = {id:arrayValueIndex.id,name:arrayValueIndex.name,active};
        setFormData({ ...formData, array: [...arrayValue] });
    };
    const handleUserTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedType(event.target.value);
    };
    const handleModalInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setModalInputValue(event.target.value);
        setFormDataModal({ ...formDataModal, name: event.target.value });
        console.log(event.target.value);
      }
    /* useEffect(() => {
        if (selectedType !== "") {
          fetchData();
        }
    }, [selectedType]); */
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await api.get(`/getSelectField/group`);

            const data: Item[] = response.data; // Assuming response.data is an array of Item objects
            var arrayValue = formData.array;

            var arrayValue: { id: number; name: string; active: boolean; }[] = [];

            const test = await setFormData({ ...formData, array: arrayValue });

            data.forEach((item: Item, index: number) => {
                arrayValue.push({id:item.id,name:item.name,active: item.active === 'Y'});
                // Create an input field dynamically using the item data
                //setInputs((prevInputs) => [...prevInputs, inputField]);
            });
            setFormData({ ...formData, array: arrayValue });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };
    
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        
        api .post('/updateValuesSelectField', formData)
          .then(response => {
            console.log(response);
            fetchData();
            Swal.fire('Sucesso', 'Grupos atualizado com sucesso!', 'success');
          })
          .catch(error => {
            // Handle the error response
            Swal.fire('Erro', 'Falha na atualização de grupos!', 'error');
          });
    };
    const handleSubmitModal = async (e: FormEvent) => {
        e.preventDefault();
        console.log(formDataModal);
        api .post('/createnewValueSelectField', formDataModal)
          .then(async response => {

            fetchData();

            setModalOpen(false);
          })
          .catch(error => {
            // Handle the error response
            console.error(error);
          });
    };
    
    document.title="Grupos | Geld";
    const [phone, setPhone] = useState("");

    const [selectedSingle, setSelectedSingle] = useState(null);

    function onPhoneChange(e: { target: { rawValue: React.SetStateAction<string>; }; }) {
        setPhone(e.target.rawValue);
    }
    const openModal = () => {
        setModalOpen(true);
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                              
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">                                    
                                        <h4 className="card-title mb-0 flex-grow-1">Grupos</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg={3}>
                                                <label htmlFor="choices-single-default" className="form-label text-muted">Tipo de Campo</label>                                                        
                                                <div className="input-group">
                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                    name = "field_type"
                                                    onChange={handleUserTypeChange}>
                                                        <option value="group">Grupo</option>
                                                    </select>
                                                    <div className="ml-3 input-group-append">
                                                        <button type="button" onClick={openModal} className="btn btn-primary waves-effect waves-light">Adicionar Nova Opção</button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                            </Col>
                                            <Col lg={3}>
                                            </Col>
                                            <Col lg={3}>
                                            </Col>
                                        </Row>
                                        <Row>
                                        { formData.array.map((item,index) => (
                                            <Col key={index} lg={3} className="m-2">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" name={item.name} value={item.name} onChange={(e) => handleChange(e, index)} />
                                                    <button type="button" onClick={() => handleChangeStatus(index,!item.active)} className="btn btn-primary waves-effect waves-light">{item.active ? 'Desativar':'Ativar'}</button>
                                                </div>
                                            </Col>
                                        ))}
                                        </Row>
                                            <button type="submit" className="btn btn-primary waves-effect waves-light">Salvar</button>
                                    </Form>
                                </CardBody>
                            </Card>
                            
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
                <ModalHeader toggle={() => setModalOpen(false)}>Adicionar novo Campo Grupo</ModalHeader>
                <ModalBody>
                 <input type="text" className="form-control" name="inputName" value={modalInputValue} onChange={handleModalInputChange} />
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={() => setModalOpen(false)}>Fechar</Button>
                <Button color="primary" onClick={handleSubmitModal}>Salvar</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
