// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-overlay-login-custom {
  background: linear-gradient(to right, #969aa8, #5c76cc) !important;
  opacity: 0.5 !important; }

.btn-primary {
  background-color: #0025be !important; }

.btn-primary.btn-animation {
  background-color: #0025be !important;
  border-color: #0025be !important; }

.btn-primary-login {
  background-color: #f9bd0c; }

/* background: #40467e42;
    opacity: 0.9;
 */
.cardBgLogin {
  --vz-card-bg: '#42428685'; }

.hide-icon {
  display: none; }

.blurred-image {
  width: 100%;
  filter: blur(5px); }

.custom-input {
  border-radius: 20px;
  /* Adjust the value for more or less curve */
  background-color: rgba(255, 255, 255, 0.2);
  /* Adjust the value for transparency */
  border: 1px solid rgba(255, 255, 255, 0.3);
  /* Optional: to make the border more visible */
  color: white;
  /* Ensures the text is visible on the transparent background */
  padding: 10px 15px;
  /* Adjust padding for better spacing */ }

/* Optional: To add focus styles */
.custom-input:focus {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/customLoginScss.scss"],"names":[],"mappings":"AAAA;EACI,kEAAkE;EAClE,uBAAuB,EAAA;;AAE3B;EACI,oCAAoC,EAAA;;AAExC;EACI,oCAAoC;EACpC,gCAAgC,EAAA;;AAEpC;EACI,yBAAyB,EAAA;;AAE7B;;EAEE;AACD;EACG,yBAAa,EAAA;;AAEhB;EACG,aAAa,EAAA;;AAEjB;EACI,WAAW;EACX,iBAAiB,EAAA;;AAErB;EACI,mBAAmB;EAAE,4CAAA;EACrB,0CAA0C;EAAE,sCAAA;EAC5C,0CAA0C;EAAE,8CAAA;EAC5C,YAAY;EAAE,8DAAA;EACd,kBAAkB;EAAE,sCAAA,EAAuC;;AAG7D,kCAAA;AACA;EACE,0CAA0C;EAC1C,sCAAsC;EACtC,4CAA4C,EAAA","sourcesContent":[".bg-overlay-login-custom {\n    background: linear-gradient(to right, #969aa8, #5c76cc) !important;\n    opacity: 0.5 !important;\n}\n.btn-primary {\n    background-color: #0025be !important;\n}\n.btn-primary.btn-animation {\n    background-color: #0025be !important;\n    border-color: #0025be !important;\n}\n.btn-primary-login{\n    background-color: #f9bd0c;\n}\n/* background: #40467e42;\n    opacity: 0.9;\n */\n .cardBgLogin{\n    --vz-card-bg: '#42428685';\n }\n .hide-icon {\n    display: none;\n  }\n.blurred-image {\n    width: 100%;\n    filter: blur(5px);\n}\n.custom-input {\n    border-radius: 20px; /* Adjust the value for more or less curve */\n    background-color: rgba(255, 255, 255, 0.2); /* Adjust the value for transparency */\n    border: 1px solid rgba(255, 255, 255, 0.3); /* Optional: to make the border more visible */\n    color: white; /* Ensures the text is visible on the transparent background */\n    padding: 10px 15px; /* Adjust padding for better spacing */\n  }\n  \n  /* Optional: To add focus styles */\n  .custom-input:focus {\n    background-color: rgba(255, 255, 255, 0.3);\n    border-color: rgba(255, 255, 255, 0.5);\n    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
