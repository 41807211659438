import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Spinner, UncontrolledDropdown, Row } from 'reactstrap';
import { api } from '../../utils/api';
import 'jspdf-autotable';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import CustomPagination from "../../components/customPagination.js"

const BaseExample = ({
  tableData,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
  total,
  isLoading,
  handleSort,
  onExportExcel,
  onExportPdf,
}) => {
  const tableRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [userLogged, setUserLogged] = useState({
    active: "",
    cell: "",
    created_at: "",
    email: "",
    email_verified_at: null,
    id: 1,
    name: "",
    photo: "",
    updated_at: "",
    user_type: ""
  });
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    fetchData()
    setFilteredData(tableData); // Initialize filteredData with the original tableData
  }, [tableData]);

  
  const fetchData = async () => {
    try {
        const responseUser = await api.get(`/getUserLogged`); // pega usuario logado
        setUserLogged(responseUser.data ?? null)
        setIsAdmin(userLogged.user_type == "administrator") // verifica se é administrador
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const handleDelete = async (communicationId) => {

    const responseVoter = await api.get(`/getCommunication/${communicationId}/delete`);
    window.location.reload();

  };
  const handleSuspended = async (communicationId) => {

    const responseVoter = await api.get(`/getCommunication/${communicationId}/suspenso`);
    window.location.reload();

  };
  const handleCanceled = async (communicationId) => {
    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: 'ALERTA!',
      text: 'Você realmente quer cancelar esta comunicação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Make the API call
          const responseVoter = await api.get(`/getCommunication/${communicationId}/cancelado`);
          // Reload the page
          Swal.fire('Sucesso', 'Comunicação cancelada com sucesso!', 'success');

          window.location.reload();
        } catch (error) {
          // Handle error, show an alert, or perform other actions
          Swal.fire('Erro', 'Falha na cancelação de cominucação!', 'error');
          console.error(error);
        }
      }
    });
  };

  const handleReactivate = async (communicationId) => {

    const responseVoter = await api.get(`/getCommunication/${communicationId}/reativar`);
    window.location.reload();

  };

  const handleExportExcel = async () => {
    setIsExportingExcel(true);

    await onExportExcel();

    setIsExportingExcel(false);
  };

  const handleExportPDF = async () => {
    setIsExportingPdf(true);

    await onExportPdf();

    setIsExportingPdf(false);
  };

  const handleFormSubmit = async (formData) => {
    try {
      // Send form data to the server
      await api.post('/submitForm', formData);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>Id</span>,
      selector: row => row.id,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Nome</span>,
      selector: row => row.name,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Data/Hora de Envio</span>,
      selector: row => `${row.send_date} ${row.send_time}`,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Recorrência</span>,
      sortable: false,
      selector: (cell) => {
        switch (cell.frequency) {
          case 'single':
            return <span className="badge bg-primary"> Disp. Unico</span>;
          case 'weekly':
            return <span className="badge bg-primary"> Semanal</span>;
          case 'monthly':
            return <span className="badge bg-primary"> Mensal</span>;
          case 'yearly':
            return <span className="badge bg-primary"> Anual</span>;
          default:
            return <span>  </span>;
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Enviado por</span>,
      selector: row => row.created_by,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Status</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.status) {
          case 'pending':
            return <span className="badge bg-warning"> Pendente</span>;
          case 'delivered':
            return <span className="badge bg-success"> Entregue</span>;
          case 'suspended':
            return <span className="badge bg-primary"> Suspenso</span>;
          case 'canceled':
            return <span className="badge bg-danger"> Cancelado</span>;
          default:
            return <span>  </span>;
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Ação</span>,
      sortable: true,

      cell: (row) => {
        let dropdownAction;
        let dropdownLabel;
        let dropdownClass;

        switch (row.status) {
          case 'pending':
            dropdownAction = handleSuspended;
            dropdownLabel = 'Suspender';
            dropdownClass = 'ri-indeterminate-circle-fill';
            break;
          case 'suspended':
            dropdownAction = handleReactivate;
            dropdownLabel = 'Reativar';
            dropdownClass = 'ri-refresh-line';
            break;
          default:
            dropdownAction = 'handleSuspended';
            dropdownLabel = 'Suspender';
            dropdownClass = 'ri-indeterminate-circle-fill';
            break;
        }

        const { id } = row;
        if (row.status == 'canceled') {
          return (
            <UncontrolledDropdown className="dropdown d-inline-block">
              <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu container='body' className="dropdown-menu-end">
                <DropdownItem className='edit-item-btn'><Link to={`/editar-comunicacao/${id}`}><i className="ri-edit-2-line"></i>Editar</Link></DropdownItem>
                <DropdownItem className='remove-item-btn'><a onClick={() => handleDelete(id)}><i className="ri-delete-bin-line"></i>Deletar</a></DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        } else {
          return (
            <UncontrolledDropdown className="dropdown d-inline-block">
              <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu container='body' className="dropdown-menu-end">
                <DropdownItem className='edit-item-btn'><Link to={`/editar-comunicacao/${id}`}><i className="ri-edit-2-line"></i>Editar</Link></DropdownItem>
                <DropdownItem className='remove-item-btn'><a onClick={() => handleDelete(id)}><i className="ri-delete-bin-line"></i>Deletar</a></DropdownItem>
                <DropdownItem className='remove-item-btn'><a onClick={() => dropdownAction(id)}><i className={dropdownClass}></i>{dropdownLabel}</a></DropdownItem>
                <DropdownItem className='remove-item-btn'><a onClick={() => handleCanceled(id)}><i className="ri-close-circle-fill"></i>Cancelar</a></DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }

      },
    },
  ];
  const customButtonStyle = {
    marginRight: '10px',
  };
  const handleFilter = (e) => {
    var searchValue = e.target.value.toLowerCase();
    if (searchValue == "pendente") {
      var searchValue = 'pending';
    }
    if (searchValue == "entregue") {
      var searchValue = 'delivered';
    }
    if (searchValue == "suspenso") {
      var searchValue = 'suspended';
    }
    if (searchValue == "cancelado") {
      var searchValue = 'canceled';
    }
    const filteredTableData = tableData.filter((item) => {
      for (const key in item) {
        if (item[key] && item[key].toString().toLowerCase().includes(searchValue)) {
          return true;
        }
      }
      return false;
    });
    setFilteredData(filteredTableData);
  };
  const exportButtons = (
    isAdmin ? (
      <Row>
        <div className='d-flex justify-content-between'>
          <div>
            <button className="btn btn-primary btn-animation waves-effect waves-light" onClick={handleExportExcel} style={customButtonStyle}>
              Excel
              {isExportingExcel && (
                <Spinner size="sm" style={{ marginLeft: 6 }} />
              )}
            </button>
            <button className="btn btn-primary btn-animation waves-effect waves-light" onClick={handleExportPDF} style={customButtonStyle}>
              PDF
              {isExportingPdf && (
                <Spinner size="sm" style={{ marginLeft: 6 }} />
              )}
            </button>
          </div>
          <div className="d-flex align-items-center">
            <i className="ri-search-line p-2"></i> <Input type="text" placeholder="Buscar" onChange={handleFilter} />
          </div>
        </div>
      </Row>
    ): null
  );

  return (
    <>
      {exportButtons}
      <div ref={tableRef}>
        <DataTable columns={columns} data={filteredData} pagination
          onSort={handleSort}
          paginationServer
          sortServer
          defaultSortFieldId={1}
          defaultSortAsc={false}
          progressPending={isLoading}
          progressComponent={<Spinner />}
          paginationTotalRows={total}
          paginationPerPage={rowsPerPage}
          paginationComponent={CustomPagination}
          noDataComponent={<div>Nenhum registro encontrado</div>}
          paginationComponentOptions={{
            rowsPerPageText: 'Dados por Pagina:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange} />
      </div>
    </>
  );
};

/* const ServerSideExample = ({tableDataSearch }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get('/getCommunications'); // Replace with your server-side endpoint
      setTableData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return <BaseExample tableData={tableData} />;
}; */

export default BaseExample;
