import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form } from 'reactstrap';
import { Link } from "react-router-dom";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';

import makeAnimated from "react-select/animated";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const animatedComponents = makeAnimated();

const SingleOptions = [
    { value: 'Choices 1', label: 'Choices 1' },
    { value: 'Choices 2', label: 'Choices 2' },
    { value: 'Choices 3', label: 'Choices 3' },
    { value: 'Choices 4', label: 'Choices 4' }
];

//import COmponents
import BaseExample from './GridTablesData';
import { api } from "../../utils/api";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export function Users() {
    document.title = "Usuários | Geld";

    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    const [selectSortRow, setselectSortRow] = useState('active');
    const [sortDirection, setsortDirection] = useState('asc');
    const [isFilter, setisFilter] = useState(false);
    const [hasEdit, sethasEdit] = useState(false);


    type Option = {
        id: number;
        name: string;
    };
    const [formData, setFormData] = useState({
        neighborhood: '',
        electoral_zone: '',
        group: '',
        reference: '',
        education: '',
        address: '',
        age: '',
        number_classification: '',
        profession: '',
        created_by: '',
        gender: '',
        otherGender: '',
    });
    useEffect(() => {
        fetchData();
    }, [currentPage, rowsPerPage, selectSortRow, sortDirection]);
    const fetchData = async () => {
        try {
            setisLoading(true);

            const response = await api.get('/getUsers', {
                params: {
                    page: currentPage, // Pass the current page directly
                    perPage: rowsPerPage, // Pass the number of rows per page
                    selectSortRow: selectSortRow, // Pass the number of rows per page
                    sortDirection: sortDirection, // Pass the number of rows per page
                },
            });

            setTotalRows(response.data.totalCount);
            setData(response.data.data);
            //setData(responseData.data);
            setisLoading(false);
            const responseAccess = await api.get(`/getAuthIdAccessEdit`);

            if (responseAccess.permissions.users.access_type == "edit") {
                sethasEdit(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setCurrentPage(1);
        setRowsPerPage(newRowsPerPage);
    };
    const handleSort = (selectSortRow: any, sortDirection: string) => {
        switch (selectSortRow.id) {
            case 1:
                setselectSortRow('name')
                break;
            case 2:
                setselectSortRow('email')
                break;
            case 3:
                setselectSortRow('user_type')
                break;
            case 4:
                setselectSortRow('cell')
                break;
            default:
                break;
        }
        setsortDirection(sortDirection)
    }

    const handleExportExcel = async () => {
        const exportData = data;

        if (exportData.length === 0) {
            exportData.push({
                'Nome': '',
                'Email': '',
                'Tipo de Usuário': '',
                'Telefone': '',
            })
        }

        const excelData = exportData.map((item) => ({
            'Nome': item.name,
            'Email': item.email,
            'Tipo de Usuário': item.user_type === 'user' ? 'Usuário' : 'Administrador',
            'Telefone': item.cell,
        }));

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const columnWidths = [
            { wch: 30 },
            { wch: 30 },
            { wch: 20 },
            { wch: 20 },
        ];
        worksheet['!cols'] = columnWidths;

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelDataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelDataBlob, 'Usuários.xlsx');
    };

    const handleExportPdf = async () => {
        let response: any;

        response = await api.get('/getUsers/pdf', {
            params: {
                formData: formData,
                selectSortRow: selectSortRow,
                sortDirection: sortDirection,
            },
            responseType: 'arraybuffer'
        });

        var pdfDataBlob = new Blob([response], { type: 'application/pdf' });

        saveAs(pdfDataBlob, 'Usuários.pdf');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Col>
                                        <div className="d-flex justify-content-start">
                                            <h4 className="card-title mb-0 flex-grow-1">Usuários</h4>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex justify-content-center">
                                            {hasEdit && (
                                                <Link to="/registro-usuário">
                                                    <button type="button" className="btn btn-primary btn-animation waves-effect waves-light" data-text="Novo Usuário"><span className="d-flex flex-row align-items-center">Novo Usuário <i className="la la-lg la-plus-square-o" style={{ marginLeft: "5px" }}></i></span></button>
                                                </Link>
                                            )}
                                        </div>
                                    </Col>
                                    <Col>
                                    </Col>
                                </CardHeader>
                                <CardBody>
                                    <BaseExample tableData={data}
                                        rowsPerPage={10}
                                        handlePageChange={handlePageChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        total={totalRows}
                                        isLoading={isLoading}
                                        handleSort={handleSort}
                                        onExportExcel={handleExportExcel}
                                        onExportPdf={handleExportPdf}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}