import React, { useEffect, useState } from 'react';
import { api } from '../utils/api';

export const GroupId = () => {
  const [options, setOptions] = useState([]);
    useEffect(() => {
      fetchData();
    }, []);
    
    const fetchData = async () => {
      try {
        const response = await api.get('/getGroup');
        setOptions(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    return (
      <select  className="form-select mb-3" id="handleGroupChange" aria-label="Default select example"
      name = "group"
      value={formData.group}
      onChange={handleGroupChange}>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    );
  };