import React, { useEffect, useState, ChangeEvent, FormEvent, useMemo } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form, Spinner, Table, FormFeedback, Input } from 'reactstrap';
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { format } from 'date-fns';
import ReactInputMask from 'react-input-mask';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

//import COmponents
import BaseExampleVoters from './GridTablesDataVotersNew';

import { api } from "../../utils/api";
import Flatpickr from 'react-flatpickr';
import { flatpickrLocale } from "../../utils/flatpickrLocale";
import { isDateToday } from "../../utils/isDateToday";

export function NewCommunications() {
    const navigate = useNavigate();
    const [optionsGroupId, setOptionsGroupId] = useState<Option[]>([]);
    const [createdById, setCreatedById] = useState<Option[]>([]);
    const [RecommendationdBy, setRecommendationdBy] = useState<Option[]>([]);

    const [files, setFiles] = useState<File[]>([]);
    const [data, setData] = useState<Table[]>([]);
    const [excludedVotersIds, setExcludedVotersIds] = useState<number[]>([]);
    const [shouldSendMessagesNow, setShouldSendMessagesNow] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    const [selectSortRow, setselectSortRow] = useState('name');
    const [sortDirection, setsortDirection] = useState('asc');
    const [isFilter, setisFilter] = useState(false);
    const [buttonText, setButtonText] = useState('Salvar');
    const [isCheckIconVisible, setIsCheckIconVisible] = useState(false);

    interface FormData {
        name: string;
        message: string;
        send_date: string;
        send_time: string;
        frequency: string;
        status: string;
        votersIds: string[];
        used_filters: string;
    }

    type Table = {
        id: number;
        nome: Text;
        Ranting: number;
        cargo: Text;
        telefone: Text;
        bairro: Text;
        isCheckIconVisible: boolean; // Add the isCheckIconVisible property
    }

    type Option = {
        id: number;
        name: string;
        created_by_recommendation: string;
    };

    const [formValidationMessages, setFormValidationMessages] = useState({
        frequency: '',
        send_date: '',
        send_time: '',
        name: '',
        message: '',
    });

    const [formDataSearch, setFormDataSearch] = useState({
        neighborhood: '',
        electoral_zone: '',
        group: '',
        section: '',
        address: '',
        birth_date: '',
        birth_year: '',
        number_classification: '',
        created_by: '',
        created_by_recommendation: '',
        school:'',
    });

    const [formData, setFormData] = useState<FormData>({
        name: '',
        message: '',
        send_date: '',
        send_time: '19:00',
        frequency: '',
        status: '',
        votersIds: [],
        
        used_filters: JSON.stringify(formDataSearch),
    });

    useEffect(() => {
        if (shouldSendMessagesNow) {
            setButtonText('Enviar Mensagem');
        } else {
            setButtonText('Salvar');
        }
        fetchDataCreatedBy();
        fetchDataRecommendationdBy();
    }, [shouldSendMessagesNow]);

    const fetchDataCreatedBy = async () => {
        try {
            const responseCreatedBy = await api.get('/getUsersSelect');
            setCreatedById(responseCreatedBy.data);
            //createdById
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchDataRecommendationdBy = async () => {
        try {
            const responseRecommendationdBy = await api.get('/getRecommendationdBySelect');
            setRecommendationdBy(responseRecommendationdBy.data);
            //createdById
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
    };

    const handleChangeMessage = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFrequencyChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setShouldSendMessagesNow(false);
        setFormData({ ...formData, frequency: event.target.value });
    };

    const handleChangeSendTime = (timeString: string) => {
        setFormData({ ...formData, send_time: timeString || '19:00' });
    };

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormDataSearch({ ...formDataSearch, [e.target.name]: e.target.value.toUpperCase() });
    };

    const handleBirthDateChange = (dateString: string) => {
        setFormDataSearch({ ...formDataSearch, birth_date: dateString });
    };

    const handleGroupChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormDataSearch({ ...formDataSearch, group: event.target.value });
    };

    const handleNumberClassificationChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormDataSearch({ ...formDataSearch, number_classification: event.target.value });
    };

    const handleCreatedByChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormDataSearch({ ...formDataSearch, created_by: event.target.value });
    };
    const handleRecommendationdByChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormDataSearch({ ...formDataSearch, created_by_recommendation: event.target.value });
    };

    const handleChangeShouldSendNow = (checked: boolean) => {
        if (checked) {
            setFormData({
                ...formData,
                send_date: format(new Date(), 'yyyy-MM-dd'), // format Y-m-d
                send_time: format(new Date(), 'HH:mm'),
            });
        }

        setShouldSendMessagesNow(checked);
    };

    const handleBlurYear = () => {
        if (Number(formDataSearch.birth_year) <= 1900) {
            setFormDataSearch({ ...formDataSearch, birth_year: '1900' });
        } else if (Number(formDataSearch.birth_year) >= 2099) {
            setFormDataSearch({ ...formDataSearch, birth_year: '2099' });
        }
    }

    const handleSubmitSearch = async (e: FormEvent) => {
        e.preventDefault();
        setisLoading(true);

        setFormData((prevState) => ({ ...prevState, votersIds: [] }));
        setExcludedVotersIds([]);

        const test = await api.post('/searchVoters', {
            params: {
                formData: formDataSearch, // Pass the current page directly
                page: currentPage, // Pass the current page directly
                perPage: rowsPerPage, // Pass the number of rows per page
                selectSortRow: selectSortRow, // Pass the number of rows per page
                sortDirection: sortDirection, // Pass the number of rows per page
                withPluckedIds: true,
            },
        })
            .then((response) => {
                setTotalRows(response.data.totalCount);

                setData(response.data.data);
                setFormData((prevState) => ({
                    ...prevState,
                    votersIds: response.data.pluckedIds?.map((voterId: number) => {
                        return 'id-' + voterId;
                    }) ?? [],
                }));
            })
            .catch((error) => {
                // Handle the error response
                console.error(error);
            });
        setisLoading(false);
        setisFilter(true);
        setFormData((prevState) => ({ ...prevState, used_filters: JSON.stringify(formDataSearch) }));
    };

    const [isVisible, setIsVisible] = useState(false);
    const spinnerHtml = ReactDOMServer.renderToString(
        <Spinner size="sm" color="primary" />
    );

    const handleClick = () => {
        setIsVisible(!isVisible);

        // Remove o ano no datepicker
        if (document.querySelectorAll('.flatpickr-current-month .numInputWrapper').length) {
            for (const yearInput of document.querySelectorAll('.flatpickr-current-month .numInputWrapper')) {
                yearInput.className = 'numInputWrapper d-none';
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, rowsPerPage, selectSortRow, sortDirection, excludedVotersIds]);

    const fetchData = async () => {
        try {
            setisLoading(true);
            if (isFilter) {
                const test = await api.post('/searchVoters', {
                    params: {
                        formData: formDataSearch, // Pass the current page directly
                        page: currentPage, // Pass the current page directly
                        perPage: rowsPerPage, // Pass the number of rows per page
                        selectSortRow: selectSortRow, // Pass the number of rows per page
                        sortDirection: sortDirection, // Pass the number of rows per page
                        withPluckedIds: true,
                        excludedVotersIds,
                    },
                })
                    .then((response) => {
                        setFormData((prevState) => ({
                            ...prevState,
                            votersIds: response.data.pluckedIds?.map((voterId: number) => {
                                return 'id-' + voterId;
                            }) ?? [],
                        }));
                        setTotalRows(response.data.totalCount);

                        setData(response.data.data);

                    })
                    .catch((error) => {
                        // Handle the error response
                        console.error(error);
                    });

                setisLoading(false);
                setisFilter(true);
            } else {
                const responseGroupSelect = await api.get('/getGroup');
                setOptionsGroupId(responseGroupSelect.data);
            }
            setisLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const isValid = validateFormData();
        if (!isValid) {
            Swal.fire('Erro', `Verifique os erros do formulário`, 'error');
            return false;
        }

        if (formData.votersIds.length === 0) {
            Swal.fire('Erro', 'Nenhum eleitor foi selecionado para envio. Utilize os filtros para buscar uma lista de eleitores para envio!', 'error');
            return false;
        }

        if (shouldSendMessagesNow) {
            Swal.fire({
                title: 'Aviso',
                html: `Aguarde enquanto enviamos mensagens! ${spinnerHtml}`,
                icon: 'warning',
                showConfirmButton: false,  // Remove the OK button
            });
        }

        var formFile = new FormData();

        if (files.length) {
            formFile.append('image', files[0], files[0].name);
        }
        Object.entries(formData).forEach(([key, value]) => {
            formFile.append(key, String(value));
        });

        formFile.append('shouldSendMessagesNow', shouldSendMessagesNow ? 'y' : '');

        api.post('/createCommunication', formFile)
            .then(response => {
                if (shouldSendMessagesNow) {
                    Swal.close();
                } else {
                    Swal.fire('Aviso', 'Sua mensagem será enviada na data programada! <br> <i class="fa fa-spinner fa-spin"></i>', 'warning');
                }
                setIsCheckIconVisible(true);

                window.location.href = window.location.href;
            })
            .catch(error => {
                // Handle the error response
                console.error(error);
                Swal.fire('Erro', 'Falha na criação de envio!', 'error');

            });
    };

    function validateFormData() {
        const errors = {
            frequency: '',
            send_date: '',
            send_time: '',
            name: '',
            message: '',
        };

        let hasError = false;

        if (!formData.frequency) {
            hasError = true;
            errors.frequency = 'A recorrência é obrigatória';
        }
        if (!formData.send_date) {
            hasError = true;
            errors.send_date = 'A data de envio é obrigatória';
        }
        if (!formData.send_time) {
            hasError = true;
            errors.send_time = 'A hora de envio é obrigatória';
        }
        if (!formData.name) {
            hasError = true;
            errors.name = 'O nome é obrigatório';
        }
        if (!formData.message && files.length === 0) {
            hasError = true;
            errors.message = 'É obrigatório informar a mensagem ou o anexo de mídia';
        }

        setFormValidationMessages(errors);

        return !hasError;
    }

    const handleDelete = (voterId: number) => {
        setFormData((prevState) => ({
            ...prevState,
            votersIds: prevState.votersIds.filter((id) => id !== 'id-' + voterId),
        }));

        setExcludedVotersIds((prevState) => {
            if (!prevState.includes(voterId)) {
                prevState.push(voterId);
                return [...prevState];
            } else {
                return prevState;
            }
        });
    };

    document.title = "Comunicação | Geld";
    const [phone, setPhone] = useState("");

    const [selectedSingle, setSelectedSingle] = useState(null);

    function onPhoneChange(e: { target: { rawValue: React.SetStateAction<string>; }; }) {
        setPhone(e.target.rawValue);
    }
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setCurrentPage(1);
        setRowsPerPage(newRowsPerPage);
    };
    const handleSort = (selectSortRow: any, sortDirection: string) => {
        switch (selectSortRow.id) {
            case 1:
                setselectSortRow('name')
                break;
            case 2:
                setselectSortRow('classification')
                break;
            case 4:
                setselectSortRow('phone')
                break;
            case 5:
                setselectSortRow('neighborhood')
                break;
            default:
                break;
        }
        setsortDirection(sortDirection)
    }
    const handleButtonClick = () => {
        // Trigger form submission programmatically
        const fakeEvent = new Event('submit') as unknown as FormEvent<HTMLFormElement>;
        handleSubmit(fakeEvent);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Novo Envio</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <span className="text-danger">* Campos obrigatórios</span>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-end">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="choices-single-default" className="form-label">
                                                        Recorrência <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className={`form-select ${!!formValidationMessages.frequency ? 'is-invalid' : ''}`}
                                                        aria-label="Default select example"
                                                        name="frequency"
                                                        value={formData.frequency}
                                                        onChange={handleFrequencyChange}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="single">Disp. Unico</option>
                                                        <option value="weekly">Semanal</option>
                                                        <option value="monthly">Mensal</option>
                                                        <option value="yearly">Anual</option>
                                                    </select>
                                                    {!!formValidationMessages.frequency && (
                                                        <FormFeedback>
                                                            {formValidationMessages.frequency}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div>
                                                    <label htmlFor="send_date" className="form-label">
                                                        Data de Envio <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="send_date"
                                                        name="send_date"
                                                        onChange={handleChange}
                                                        value={formData.send_date}
                                                        invalid={!!formValidationMessages.send_date}
                                                        disabled={shouldSendMessagesNow}
                                                    />
                                                    {!!formValidationMessages.send_date && (
                                                        <FormFeedback>
                                                            {formValidationMessages.send_date}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div>
                                                    <label htmlFor="send_time" className="form-label">
                                                        Hora de Envio <span className="text-danger">*</span>
                                                    </label>
                                                    <Flatpickr
                                                        id="send_time"
                                                        name="send_time"
                                                        className="form-control"
                                                        options={{
                                                            locale: flatpickrLocale,
                                                            enableTime: true,
                                                            noCalendar: true,
                                                            time_24hr: true,
                                                        }}
                                                        onChange={(dates, currentTimeString) => handleChangeSendTime(currentTimeString)}
                                                        onChangeCapture={(e) => !e.currentTarget.value && handleChangeSendTime('19:00')}
                                                        value={formData.send_time}
                                                        disabled={shouldSendMessagesNow}
                                                    />
                                                    {!!formValidationMessages.send_time && (
                                                        <FormFeedback>
                                                            {formValidationMessages.send_time}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            {formData.frequency === 'single' && (
                                                <Col lg={2}>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="shouldSendMessagesNow"
                                                            checked={shouldSendMessagesNow}
                                                            onChange={(e) => handleChangeShouldSendNow(e.target.checked)}
                                                        />
                                                        <label htmlFor="shouldSendMessagesNow">Enviar Agora</label>
                                                    </div>
                                                </Col>
                                            )}
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="inputName" className="form-label">
                                                        Nome do Envio <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Digite o Nome"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.name}
                                                    />
                                                    {!!formValidationMessages.name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.name}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <div>
                                                    <label htmlFor="message" className="form-label">
                                                        Mensagem
                                                    </label>
                                                    <textarea
                                                        className={`form-control ${!!formValidationMessages.message ? 'is-invalid' : ''}`}
                                                        id="message"
                                                        name="message"
                                                        onChange={handleChangeMessage}
                                                        value={formData.message}
                                                    ></textarea>
                                                    {!!formValidationMessages.message && (
                                                        <FormFeedback>
                                                            {formValidationMessages.message}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <label htmlFor="exampleFormControlTextarea5" className="form-label">Anexar Mídia</label>
                                                <FilePond
                                                    files={files}
                                                    onupdatefiles={(fileItems) => setFiles([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                    allowReorder={true}
                                                    allowMultiple={false}
                                                    labelIdle={`
                                                        <div class="d-flex flex-row align-items-center justify-content-center">
                                                            <img src="/icons/upload.png" />
                                                            <b style="color: #888; margin-left: 15px">Arraste ou clique para upload da foto</b>
                                                        </div>
                                                    `}
                                                    id="fileUpload"
                                                    name="fileUpload"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex justify-content-center">
                                                <button type="button" onClick={handleClick} className="btn btn-primary btn-animation waves-effect waves-light" data-text="Filtro Avançado"><span>Filtro Avançado</span></button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>

                                <CardBody id="CardAdvancedSearch" className={`my-element ${isVisible ? '' : 'd-none'}`}>
                                    <Form onSubmit={handleSubmitSearch}>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="neighborhood" className="form-label">Bairro</label>
                                                    <input type="text" className="form-control" id="neighborhood"
                                                        name="neighborhood"
                                                        value={formDataSearch.neighborhood}
                                                        onChange={handleChangeSearch} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="electoral_zone" className="form-label">Zona Eleitoral</label>
                                                    <input type="text" className="form-control" id="electoral_zone"
                                                        name="electoral_zone"
                                                        value={formDataSearch.electoral_zone}
                                                        onChange={handleChangeSearch} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="group" className="form-label">Grupo</label>
                                                    <select className="form-select mb-3" id="handleGroupChange" aria-label="Default select example"
                                                        name="group"
                                                        value={formDataSearch.group}
                                                        onChange={handleGroupChange}>
                                                        <option value="">Selecione um Grupo</option>
                                                        {optionsGroupId.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="section" className="form-label">Seção</label>
                                                    <input type="text" className="form-control" id="section"
                                                        name="section"
                                                        value={formDataSearch.section}
                                                        onChange={handleChangeSearch} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="address" className="form-label">Endereço</label>
                                                    <input type="text" className="form-control" id="address"
                                                        name="address"
                                                        value={formDataSearch.address}
                                                        onChange={handleChangeSearch} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="birth_date" className="form-label">Dia / Mês de Nascimento</label>
                                                    <ReactInputMask
                                                        id="birth_date"
                                                        name="birth_date"
                                                        className="form-control day-month-selector"
                                                        mask="99/99/9999" // Mask for date input
                                                        placeholder="DD/MM/AAAA" // Placeholder for date input
                                                        onChange={(e) => handleBirthDateChange(e.target.value)}
                                                        value={formDataSearch.birth_date}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="birth_year" className="form-label">Ano de Nascimento</label>
                                                    <input type="number"
                                                        className="form-control show-arrows"
                                                        id="birth_year"
                                                        name="birth_year"
                                                        value={formDataSearch.birth_year}
                                                        min={1900}
                                                        max={2099}
                                                        onChange={handleChangeSearch}
                                                        onBlur={(e) => handleBlurYear()}
                                                        inputMode="numeric"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                            <div>
                                                    <label htmlFor="created_by" className="form-label">Cadastrado por</label>
                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                        name="created_by"
                                                        value={formDataSearch.created_by}
                                                        onChange={handleCreatedByChange}>
                                                        <option value="0">Selecione</option>
                                                        {createdById.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="created_by_recommendation" className="form-label">Indicado por</label>
                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                        name="created_by_recommendation"
                                                        value={formDataSearch.created_by_recommendation}
                                                        onChange={handleRecommendationdByChange}>
                                                        <option value="0">Selecione</option>
                                                        {RecommendationdBy.map((option) => (
                                                            <option key={option.created_by_recommendation} value={option.created_by_recommendation}>
                                                                {option.created_by_recommendation}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="school" className="form-label">Escola</label>
                                                    <input type="text" className="form-control" id="school"
                                                        name="school"
                                                        value={formDataSearch.school}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                            </Col>
                                        </Row>
                                        <button type="submit" className="btn btn-primary waves-effect waves-light">Pesquisar</button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Eleitores</h4>
                                    </div>
                                </CardHeader>

                                <CardBody>
                                    <BaseExampleVoters tableData={data} onDelete={handleDelete}
                                        rowsPerPage={10}
                                        handlePageChange={handlePageChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        total={totalRows}
                                        isLoading={isLoading}
                                        handleSort={handleSort}
                                        isCheckIconVisible={isCheckIconVisible} // Pass the isCheckIconVisible state
                                    />
                                    <Row>
                                        <Col>
                                            <button type="button" className="btn btn-primary waves-effect waves-light" onClick={handleButtonClick}>{buttonText}</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

