import { useCallback, useEffect, useState, } from "react";
import { useLocation } from "react-router";

export default function useQueryParams() {
    const location = useLocation();
    const [queryParams, setQueryParams] = useState<URLSearchParams>();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setQueryParams(params);
    }, [location])

    const getQueryParam = useCallback((name: string) => {
        if (!queryParams) return null;

        return queryParams.get(name);
    }, [queryParams]);

    return {
        queryParams,
        getQueryParam,
    };
}