const CustomPagination = (props) => {
  const { rowsPerPage, currentPage, totalPages, onChangePage, onChangeRowsPerPage, paginationRowsPerPageOptions, rowCount } = props;

  const isLastPage = currentPage * rowsPerPage >= rowCount;

  return (
    <div className="d-flex justify-content-end align-items-center">
      Dados por Página
      <select
        value={rowsPerPage}
        onChange={(e) => onChangeRowsPerPage(Number(e.target.value))} className="m-2"
      >
        {paginationRowsPerPageOptions.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <span className="m-2">
        {`Mostrando ${((currentPage - 1) * rowsPerPage) + 1}-${Math.min(
          (currentPage) * rowsPerPage, rowCount
        )} de ${rowCount}`}
      </span>
      <button
        onClick={() => onChangePage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {'<'}
      </button>

      <button
        onClick={() => onChangePage(currentPage + 1)}
        disabled={isLastPage}
      >
        {'>'}
      </button>
    </div>
  );
}
export default CustomPagination