import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form, FormFeedback, Input, Spinner } from 'reactstrap';
import { Link, useParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br.js";
import 'filepond/dist/filepond.min.css';

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Swal from 'sweetalert2';
import "./filepondCustom.css";
import { api } from "../../utils/api";
import TableListUserLoginHistory from './TableListUserLoginHistory';
import ReactSelect from 'react-select';
import { getMonthNumberStringFromMonthNumber } from "../../utils/getMonthNumberStringFromMonthNumber";
import { selectMonthOptions } from "../../utils/selectMonthOptions";
import { format } from "date-fns";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export function EditUsers() {
    const { userId } = useParams();
    const [files, setFiles] = useState<File[]>([]);
    const [isVotersChecked, setVotersChecked] = useState(false);
    const [isComunicationChecked, setComunicationChecked] = useState(false);
    const [isServiceChecked, setServiceChecked] = useState(false);
    const [isParametersChecked, setParametersChecked] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [createdAt, setCreatedAt] = useState(new Date());

    // Histórico de Acesso (login)
    const [loginHistoryCurrentPage, setLoginHistoryCurrentPage] = useState(1);
    const [loginHistoryRowsPerPage, setLoginHistoryRowsPerPage] = useState(10);
    const [loginHistorySelectSortRow, setLoginHistorySelectSortRow] = useState('id');
    const [loginHistorySortDirection, setLoginHistorySortDirection] = useState('desc');
    const [loginHistory, setLoginHistory] = useState([]);
    const [loginHistoryTotalRows, setLoginHistoryTotalRows] = useState(0);
    const [loginHistoryIsLoading, setLoginHistoryIsLoading] = useState(false);

    // Quantidade de eleitores
    const [month, setMonth] = useState(getMonthNumberStringFromMonthNumber((new Date()).getMonth()));
    const [year, setYear] = useState((new Date()).getFullYear());
    const [votersTotalsCount, setVotersTotalsCount] = useState(0);
    const [votersTotalsIsLoading, setVotersTotalsIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        user_type: '',
        password: '',
        photo: '',
        switchVotersAccess: '',
        voterDashboardShortcut: 'N',
        switchComunicationAccess: '',
        communicationDashboardShortcut: 'N',
        switchServiceAccess: '',
        serviceDashboardShortcut: 'N',
        switchParametersUserAccess: '',
        switchParametersSelectFieldAccess: '',
        user_status: '',
        password_confirm: '',
    });
    const [formValidationMessages, setFormValidationMessages] = useState({
        name: '',
        email: '',
        user_type: '',
        password: '',
        password_confirm: ''
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
    };

    const handleCheckPermission = (field: string, value: string, checked: boolean) => {
        setFormData((prevState) => ({ ...prevState, [field]: checked ? value : 'none' }));
    }

    const handleCheckDashboardPermission = (field: string, checked: boolean) => {
        setFormData((prevState) => ({ ...prevState, [field]: checked ? 'Y' : 'N' }));
    }

    const handleChangeCheckBox = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleChangeCheckBoxSelectFields = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: checked ? 'edit' : '',
        }));
    };
    const handleUserTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, user_type: event.target.value });
    };
    const handleUserStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, user_status: event.target.value });
    };
    const handleVotersSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVotersChecked(event.target.checked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            switchVotersAccess: event.target.checked ? 'view' : 'none',
            voterDashboardShortcut: event.target.checked ? prevFormData.voterDashboardShortcut : 'N',
        }));
    };
    const handleComunicationSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComunicationChecked(event.target.checked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            switchComunicationAccess: event.target.checked ? 'view' : 'none',
            communicationDashboardShortcut: event.target.checked ? prevFormData.communicationDashboardShortcut : 'N',
        }));
    };
    const handleServiceSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceChecked(event.target.checked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            switchServiceAccess: event.target.checked ? 'view' : 'none',
            serviceDashboardShortcut: event.target.checked ? prevFormData.serviceDashboardShortcut : 'N',
        }));
    };
    const handleParametersSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParametersChecked(event.target.checked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            switchParametersUserAccess: event.target.checked ? 'view' : 'none',
            switchParametersSelectFieldAccess: event.target.checked ? 'edit' : 'none',
        }));
    };
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const responseAccess = await api.get(`/getAuthIdAccessEdit`);
            if (responseAccess.permissions.users.access_type != "edit") {
                setIsReadOnly(true);
            }
            const responseGroupSelect = await api.get(`/getUserAccess/${userId}`);

            const responseUser = await api.get(`/getUser/${userId}`);
            //setFormData(responseUser.data);
            //? "on " : "off"
            setFormData({
                name: responseUser.data.name ?? '',
                phone: responseUser.data.cell ?? '',
                email: responseUser.data.email ?? '',
                user_type: responseUser.data.user_type ?? '',
                password: '',
                photo: responseUser.data.photo ?? '',

                switchVotersAccess: responseGroupSelect.data.switchVotersAccess ? responseGroupSelect.data.switchVotersAccess.access_type : '',
                voterDashboardShortcut: responseGroupSelect.data.switchVotersAccess && responseGroupSelect.data.switchVotersAccess.dashboard_create_shortcut === 'Y' ? 'Y' : 'N',
                switchComunicationAccess: responseGroupSelect.data.switchComunicationAccess ? responseGroupSelect.data.switchComunicationAccess.access_type : '',
                communicationDashboardShortcut: responseGroupSelect.data.switchComunicationAccess && responseGroupSelect.data.switchComunicationAccess.dashboard_create_shortcut === 'Y' ? 'Y' : 'N',
                switchServiceAccess: responseGroupSelect.data.switchServiceAccess ? responseGroupSelect.data.switchServiceAccess.access_type : '',
                serviceDashboardShortcut: responseGroupSelect.data.switchServiceAccess && responseGroupSelect.data.switchServiceAccess.dashboard_create_shortcut === 'Y' ? 'Y' : 'N',
                switchParametersUserAccess: responseGroupSelect.data.switchParametersUserAccess ? responseGroupSelect.data.switchParametersUserAccess.access_type : '',
                switchParametersSelectFieldAccess: responseGroupSelect.data.switchParametersSelectFieldAccess ? responseGroupSelect.data.switchParametersSelectFieldAccess.access_type : '',
                user_status: responseUser.data.active ?? '',
                password_confirm: '',
            })
            if (responseGroupSelect.data.switchVotersAccess) {
                setVotersChecked(true);
                setIsChecked(!isChecked);
            }
            if (responseGroupSelect.data.switchComunicationAccess) {
                setComunicationChecked(true);
            }
            if (responseGroupSelect.data.switchServiceAccess) {
                setServiceChecked(true);
            }
            if (responseGroupSelect.data.switchParametersUserAccess || responseGroupSelect.data.switchParametersSelectFieldAccess) {
                setParametersChecked(true);
            }
            if (responseUser.data.photo) {
                const response = await fetch(process.env.REACT_APP_API_URL + 'getImage/user/' + responseUser.data.photo ?? '');
                const data = await response.blob();
                const convertedFile = new File([data], responseUser.data.photo ?? '', { type: data.type });
                setFiles([convertedFile]);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleEdit = (e: FormEvent) => {
        e.preventDefault();

        const isValid = validateFormData();
        if (!isValid) {
            Swal.fire('Erro', `Verifique os erros do formulário`, 'error');
            return false;
        }

        var formFile = new FormData();
        if (files.length) {
            formFile.append('image', files[0], files[0].name);
        }
        Object.entries(formData).forEach(([key, value]) => {
            formFile.append(key, String(value));
        });
        api.post(`/editUser/${userId}`, formFile)
            .then(response => {
                // Handle the success response
                //navigate('/eleitores');
                Swal.fire('Sucesso', 'Usuário Atualizado com Sucesso!', 'success');

            })
            .catch(error => {
                // Handle the error response
                console.error(error);
                Swal.fire('Erro', 'Falha na atualização de usuário!', 'error');

            });
    };

    function validateFormData() {
        const errors = {
            name: '',
            email: '',
            user_type: '',
            password: '',
            password_confirm: ''
        };

        let hasError = false;

        if (!formData.name) {
            hasError = true;
            errors.name = 'O nome é obrigatório';
        }
        if (!formData.email) {
            hasError = true;
            errors.email = 'O email é obrigatório';
        }
        if (!formData.user_type) {
            hasError = true;
            errors.user_type = 'O tipo de usuário é obrigatório';
        }
        if (formData.password !== formData.password_confirm) {
            hasError = true;
            errors.password = 'As senhas não coincidem';
            errors.password_confirm = 'As senhas não coincidem';
        }

        setFormValidationMessages(errors);

        return !hasError;
    }

    document.title = "Usuários | Geld";

    useEffect(() => {
        fetchLoginHistory();
    }, [loginHistoryCurrentPage, loginHistoryRowsPerPage, loginHistorySelectSortRow, loginHistorySortDirection, userId]);

    const fetchLoginHistory = async () => {
        try {
            setLoginHistoryIsLoading(true);
            const response = await api.get(`/getUserLoginHistory/${userId}`, {
                params: {
                    page: loginHistoryCurrentPage, // Pass the current page directly
                    perPage: loginHistoryRowsPerPage, // Pass the number of rows per page
                    selectSortRow: loginHistorySelectSortRow, // Pass the number of rows per page
                    sortDirection: loginHistorySortDirection, // Pass the number of rows per page,
                },
            });

            setLoginHistory(response.data);
            setLoginHistoryTotalRows(response.data.totalCount);
            setLoginHistoryIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleLoginHistoryPageChange = (page: number) => {
        setLoginHistoryCurrentPage(page);
    };

    const handleLoginHistoryRowsPerPageChange = (newRowsPerPage: number) => {
        setLoginHistoryCurrentPage(1);
        setLoginHistoryRowsPerPage(newRowsPerPage);
    };

    const handleLoginHistorySort = (selectSortRow: any, sortDirection: string) => {
        switch (selectSortRow.id) {
            case 1:
                setLoginHistorySelectSortRow('id')
                break;
            case 2:
                setLoginHistorySelectSortRow('created_at')
                break;
            case 3:
                setLoginHistorySelectSortRow('ip_address')
                break;
            default:
                break;
        }
        setLoginHistorySortDirection(sortDirection)
    }

    const handleBlurYear = () => {
        if (year <= 2000) {
            setYear(2000);
        } else if (year >= 2099) {
            setYear(2099);
        }
    }

    useEffect(() => {
        async function getVotersTotalsCount() {
            if (month && year >= 2000 && year <= 2099 && userId) {
                setVotersTotalsIsLoading(true);

                const responseVotersTotals = await api.get(`/getUserVotersTotals/${userId}`, {
                    params: {
                        month,
                        year,
                    }
                });

                setVotersTotalsCount(responseVotersTotals.count);

                setVotersTotalsIsLoading(false);
            }
        }

        getVotersTotalsCount();
    }, [month, year, userId]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Editar Usuário</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleEdit}>
                                        <Row>
                                            <Col>
                                                <span className="text-danger">* Campos obrigatórios</span>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4}></Col>
                                            <Col lg={4} className="d-flex justify-content-center">
                                                <FilePond
                                                    className='filepond filepond-container filepond-photo'
                                                    files={files}
                                                    onupdatefiles={(fileItems) => setFiles([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                    allowReorder={true}
                                                    allowMultiple={false}
                                                    labelIdle={`
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <img src="/icons/upload.png" />
                                                            <b style="color: #888">Arraste ou clique para upload da foto</b>
                                                        </div>
                                                    `}
                                                    id="fileUpload"
                                                    name="fileUpload"
                                                    acceptedFileTypes={['image/*']} // Restrict to image files
                                                    stylePanelAspectRatio={(300 / 440).toString()} // Convert the aspect ratio to a string
                                                />
                                            </Col>
                                            <Col lg={4}></Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="inputName" className="form-label">
                                                        Nome <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputName"
                                                        placeholder="Digite seu Nome"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.name}
                                                        readOnly={isReadOnly}
                                                    />
                                                    {!!formValidationMessages.name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.name}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="inputEmail" className="form-label">
                                                        Email <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail"
                                                        placeholder="exemplo@exemplo.com"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.email}
                                                        readOnly={isReadOnly}
                                                    />
                                                    {!!formValidationMessages.email && (
                                                        <FormFeedback>
                                                            {formValidationMessages.email}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>

                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="cleave-phone" className="form-label">Telefone</label>
                                                    <Cleave placeholder="xx xxxx-xxxx"
                                                        options={{
                                                            phone: true,
                                                            phoneRegionCode: "br"
                                                        }}
                                                        className="form-control"
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                        readOnly={isReadOnly}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="choices-single-default" className="form-label text-muted">
                                                        Tipo de Usuário <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className={`form-select ${!!formValidationMessages.user_type ? 'is-invalid' : ''}`}
                                                        aria-label="Default select example"
                                                        name="user_type"
                                                        value={formData.user_type}
                                                        onChange={handleUserTypeChange}
                                                        disabled={isReadOnly}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="administrator">Administrador</option>
                                                        <option value="user">Usuário</option>
                                                    </select>
                                                    {!!formValidationMessages.user_type && (
                                                        <FormFeedback>
                                                            {formValidationMessages.user_type}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="password" className="form-label">Senha</label>
                                                    <Input
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        placeholder="Digite sua Senha"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.password}
                                                        readOnly={isReadOnly}
                                                    />
                                                    {!!formValidationMessages.password && (
                                                        <FormFeedback>
                                                            {formValidationMessages.password}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="password_confirm" className="form-label">Confirmar Senha</label>
                                                    <Input
                                                        type="password"
                                                        className="form-control"
                                                        id="password_confirm"
                                                        placeholder="Confirme sua senha"
                                                        name="password_confirm"
                                                        value={formData.password_confirm}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.password_confirm}
                                                        readOnly={isReadOnly}
                                                    />
                                                    {!!formValidationMessages.password_confirm && (
                                                        <FormFeedback>
                                                            {formValidationMessages.password_confirm}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>

                                            <Row>
                                                <Col lg={6}>
                                                    <label className="mt-3 form-label">Cadastrado em: {format(new Date(createdAt), "dd/MM/yyyy - HH'h':mm")}</label>
                                                </Col>
                                            </Row>
                                        </Row>
                                        <Card className="mt-4">
                                            <CardHeader style={{ backgroundColor: '#f1f1f1' }}>
                                                <Row>
                                                    <h5 className="card-title">Permissões de Usuário</h5>
                                                    <div className="rounded-circle p-1" style={{ backgroundColor: '#FFFFFF' }} ></div>
                                                </Row>
                                            </CardHeader>
                                            <CardBody style={{ backgroundColor: '#f1f1f1' }}>
                                                <Row>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                        <Row>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <div className="form-check form-switch form-switch-secondary">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchVoters" name="switchVoters" onChange={handleVotersSwitchChange} checked={isVotersChecked} disabled={isReadOnly} />
                                                                    <label className="form-check-label" htmlFor="switchVoters">Eleitores</label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <Row>
                                                                    {/* Render the additional div conditionally */}
                                                                    {isVotersChecked && (
                                                                        <>
                                                                            <Col lg={12}>
                                                                                <div className="">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchVotersAccess"
                                                                                        id="switchVotersAccessview"
                                                                                        value="view"
                                                                                        onChange={(e) => handleCheckPermission('switchVotersAccess', 'view', e.target.checked)}
                                                                                        checked={formData.switchVotersAccess === 'view'}
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Visualizar ou Visualizar/Editar </label>
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchVotersAccess"
                                                                                        id="switchVotersAccessEdit"
                                                                                        value="edit"
                                                                                        onChange={(e) => handleCheckPermission('switchVotersAccess', 'edit', e.target.checked)}
                                                                                        checked={formData.switchVotersAccess === 'edit'}
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <div className="d-flex justify-content-center mt-2">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="voterDashboardShortcut"
                                                                                        id="voterDashboardShortcutCheck"
                                                                                        onChange={(e) => handleCheckDashboardPermission('voterDashboardShortcut', e.target.checked)}
                                                                                        checked={formData.voterDashboardShortcut === 'Y'}
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Atalho dashboard </label>
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                        <Row>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <div className="form-check form-switch form-switch-secondary">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchComunication" onChange={handleComunicationSwitchChange} checked={isComunicationChecked} disabled={isReadOnly} />
                                                                    <label className="form-check-label" htmlFor="switchComunication">Comunicação</label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <Row>
                                                                    {/* Render the additional div conditionally */}
                                                                    {isComunicationChecked && (
                                                                        <>
                                                                            <Col lg={12}>
                                                                                <div className="">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchComunicationAccess"
                                                                                        id="switchComunicationAccess"
                                                                                        value="view"
                                                                                        onChange={(e) => handleCheckPermission('switchComunicationAccess', 'view', e.target.checked)}
                                                                                        checked={formData.switchComunicationAccess === 'view'}
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Visualizar ou Visualizar/Editar </label>
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchComunicationAccess"
                                                                                        id="switchComunicationAccess"
                                                                                        value="edit"
                                                                                        onChange={(e) => handleCheckPermission('switchComunicationAccess', 'edit', e.target.checked)}
                                                                                        checked={formData.switchComunicationAccess === 'edit'}
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <div className="d-flex justify-content-center mt-2">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="communicationDashboardShortcut"
                                                                                        id="communicationDashboardShortcutCheck"
                                                                                        onChange={(e) => handleCheckDashboardPermission('communicationDashboardShortcut', e.target.checked)}
                                                                                        checked={formData.communicationDashboardShortcut === 'Y'}
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Atalho dashboard </label>
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                        <Row>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <div className="form-check form-switch form-switch-secondary">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchService" onChange={handleServiceSwitchChange} checked={isServiceChecked} disabled={isReadOnly} />
                                                                    <label className="form-check-label" htmlFor="switchService">Demanda</label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <Row>
                                                                    {/* Render the additional div conditionally */}
                                                                    {isServiceChecked && (
                                                                        <>
                                                                            <Col lg={12}>
                                                                                <div className="">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchServiceAccess"
                                                                                        id="switchServiceAccess"
                                                                                        value="view"
                                                                                        onChange={(e) => handleCheckPermission('switchServiceAccess', 'view', e.target.checked)}
                                                                                        checked={formData.switchServiceAccess === 'view'}
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Visualizar ou Visualizar/Editar </label>
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="switchServiceAccess"
                                                                                        id="switchServiceAccess"
                                                                                        value="edit"
                                                                                        onChange={(e) => handleCheckPermission('switchServiceAccess', 'edit', e.target.checked)}
                                                                                        checked={formData.switchServiceAccess === 'edit'}
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <div className="d-flex justify-content-center mt-2">
                                                                                    <input
                                                                                        className="form-check-input mx-1"
                                                                                        type="checkbox"
                                                                                        name="serviceDashboardShortcut"
                                                                                        id="serviceDashboardShortcutCheck"
                                                                                        onChange={(e) => handleCheckDashboardPermission('serviceDashboardShortcut', e.target.checked)}
                                                                                        checked={formData.serviceDashboardShortcut === 'Y'}
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                    <label className="form-check-label mr-2 ml-2"> Atalho dashboard </label>
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-5">
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                    </Col>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                        <Row>
                                                            <Col lg={12} className="d-flex justify-content-center">
                                                                <div className="form-check form-switch form-switch-secondary">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchParameters" onChange={handleParametersSwitchChange} checked={isParametersChecked} disabled={isReadOnly} />
                                                                    <label className="form-check-label" htmlFor="switchParameters">Parâmetros</label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12}>
                                                                {isParametersChecked && (
                                                                    <Row>
                                                                        <Col lg={4} className="d-flex justify-content-center">Visualizar</Col>
                                                                        <Col lg={4}></Col>
                                                                        <Col lg={4} className="d-flex justify-content-center">Editar</Col>

                                                                        <Col lg={4} className="d-flex justify-content-center"><input className="form-check-input" type="radio" name="switchParametersUserAccess" id="switchParametersUserAccess" value="view" onChange={handleChangeCheckBox} checked={formData.switchParametersUserAccess === 'view'} disabled={isReadOnly} /></Col>
                                                                        <Col lg={4}>Usuários</Col>
                                                                        <Col lg={4} className="d-flex justify-content-center"><input className="form-check-input" type="radio" name="switchParametersUserAccess" id="switchParametersUserAccess" value="edit" onChange={handleChangeCheckBox} checked={formData.switchParametersUserAccess === 'edit'} disabled={isReadOnly} /></Col>

                                                                        <Col lg={4} className="d-flex justify-content-center"></Col>
                                                                        <Col lg={4}>Grupos</Col>
                                                                        <Col lg={4} className="d-flex justify-content-center"><input className="form-check-input rounded-circle" type="checkbox" name="switchParametersSelectFieldAccess" id="switchParametersSelectFieldAccess" value="edit" onChange={handleChangeCheckBoxSelectFields} checked={formData.switchParametersSelectFieldAccess === 'edit'} disabled={isReadOnly} /></Col>
                                                                    </Row>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4} className="d-flex justify-content-center">
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <div>
                                            <label htmlFor="choices-single-default" className="form-label text-muted">Usuário Ativo</label>
                                            <select className="form-select mb-3" aria-label="Default select example" name="user_status"
                                                value={formData.user_status}
                                                onChange={handleUserStatusChange}
                                                disabled={isReadOnly}>
                                                <option value="">Selecione</option>
                                                <option value="ativo">Ativo</option>
                                                <option value="inativo">Inativo</option>
                                            </select>
                                        </div>
                                        <button type="submit" className="btn btn-primary waves-effect waves-light">Salvar</button>
                                    </Form>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Histórico de Acesso</h4>
                                    </div>
                                </CardHeader>

                                <CardBody style={{ maxWidth: '70%' }}>
                                    <TableListUserLoginHistory
                                        tableData={loginHistory}
                                        rowsPerPage={loginHistoryRowsPerPage}
                                        handlePageChange={handleLoginHistoryPageChange}
                                        handleRowsPerPageChange={handleLoginHistoryRowsPerPageChange}
                                        total={loginHistoryTotalRows}
                                        isLoading={loginHistoryIsLoading}
                                        handleSort={handleLoginHistorySort}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Eleitores cadastrados pelo usuário</h4>
                                    </div>
                                </CardHeader>

                                <CardBody>
                                    <Row className="mb-5">
                                        <Col lg={3}>
                                            <label htmlFor="month" className="form-label">
                                                Mês
                                            </label>
                                            <ReactSelect
                                                id="month"
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isSearchable
                                                options={selectMonthOptions}
                                                value={selectMonthOptions.find((monthItem) => monthItem.value === month)}
                                                onChange={(newMonth) => setMonth(newMonth?.value ?? '01')}
                                                placeholder="Selecione o mês"
                                                noOptionsMessage={() => 'Nenhum resultado encontrado'}
                                            />
                                        </Col>
                                        <Col lg={3}>
                                            <label htmlFor="year" className="form-label">
                                                Ano
                                            </label>
                                            <input type="number"
                                                className="form-control show-arrows"
                                                id="year"
                                                name="year"
                                                value={year}
                                                min={2000}
                                                max={2099}
                                                onChange={(e) => setYear(Number(e.target.value))}
                                                onBlur={(e) => handleBlurYear()}
                                                inputMode="numeric"
                                            />
                                        </Col>
                                    </Row>

                                    {votersTotalsIsLoading && (
                                        <Row>
                                            <Col className="d-flex justify-content-center">
                                                <Spinner />
                                            </Col>
                                        </Row>
                                    )}

                                    {!votersTotalsIsLoading && (
                                        <Row>
                                            <Col>
                                                <h5 style={{ fontWeight: 'normal' }}>Eleitores cadastrados no mês: <strong>{votersTotalsCount}</strong></h5>
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
