import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../utils/api";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isVoters, setIsVoters] = useState(false);
    const [isCommunication, setIsCommunication] = useState(false);
    const [isServices, setIsServices] = useState(false);
    const [isParameters, setIsParameters] = useState(false);
    const [isUsers, setIsUsers] = useState(false);
    const [isGroups, setIsGroups] = useState(false);

    // Apps
    const [isEmail, setEmail] = useState(false);
    const [isSubEmail, setSubEmail] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [isProjects, setIsProjects] = useState(false);
    const [isTasks, setIsTasks] = useState(false);
    const [isCRM, setIsCRM] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isSupportTickets, setIsSupportTickets] = useState(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    const [isJobs, setIsJobs] = useState(false);
    const [isJobList, setIsJobList] = useState(false);
    const [isCandidateList, setIsCandidateList] = useState(false);

    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);

    // Charts
    const [isApex, setIsApex] = useState(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState(false);
    const [isLevel2, setIsLevel2] = useState(false);
    const [userPermissions, setUserPermissions] = useState({});

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Eleitores') {
            setIsVoters(false);
        }
        if (iscurrentState !== 'Comunicação') {
            setIsCommunication(false);
        }
        if (iscurrentState !== 'Demanda') {
            setIsServices(false);
        }
        if (iscurrentState !== 'Parâmetros') {
            setIsParameters(false);
        }
        if (iscurrentState !== 'Usuários') {
            setIsUsers(false);
        }
        if (iscurrentState !== 'Grupos') {
            setIsGroups(false);
        }
        fetchData();
    }, [
        history,
        iscurrentState,
        isDashboard,
        isVoters,
        isCommunication,
        isServices,
        isParameters,
        isUsers,
        isGroups,
    ]);
    const fetchData = async () => {
        try {
          const responseAccess = await api.get(`/getAuthIdAccess`);
          setUserPermissions(responseAccess.permissions);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Painel",
            icon: "las la-tachometer-alt",
            link: "/",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Dashboard');
            }
        },
        {
            id: "voters",
            label: "Eleitores",
            icon: "las la-user-friends",
            link: "/eleitores",
            permission: "voters",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Eleitores');
            }
        },
        {
            id: "communication",
            label: "Comunicação",
            icon: "bx bx-conversation",
            link: "/comunicacao",
            permission: "communication",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Comunicação');
            }
        },
        {
            id: "services",
            label: "Demanda",
            icon: "ri-file-list-line",
            link: "/demanda",
            permission: "services",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Demanda');
            }
        },
        {
            id: "parameters",
            label: "Parâmetros",
            icon: "las la-cog",
            permission: "parameters",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsParameters(!isParameters);
                setIscurrentState('Parâmetros');
                updateIconSidebar(e);
            },
            stateVariables: isParameters,
            subItems: [
                {
                    id: "users",
                    label: "Usuários",
                    link: "/usuários",
                    permission: "users",
                    parentId: "parameters",
                    click: function (e) {
                        e.preventDefault();
                        setIscurrentState('Usuários');
                    }
                },
                {
                    id: "groups",
                    label: "Grupos",
                    link: "/campos-de-selecao",
                    permission: "groups",
                    parentId: "parameters",
                    click: function (e) {
                        e.preventDefault();
                        setIscurrentState('Grupos');
                    }
                },
            ],
        },

    ];
    const filteredMenuItems = menuItems
        .filter((item) => {
        const hasPermission = !item.permission || userPermissions[item.permission];
        return hasPermission;
        })
        .map((item) => {
        if (item.subItems) {
            const shouldShowSubItems = isParameters && item.id === "parameters";
            const filteredSubItems = item.subItems.filter((subItem) => {
            const hasPermission = !subItem.permission || userPermissions[subItem.permission];
            return hasPermission;
            });
            return {
            ...item,
            stateVariables: shouldShowSubItems,
            subItems: filteredSubItems,
            };
        }
        return item;
    });
    return <React.Fragment>{filteredMenuItems}</React.Fragment>;
};
export default Navdata;