export function getCommunicationFrequencyTextFormatted(frequency: string) {
    switch (frequency) {
        case 'single':
            return 'Disp.Unico';
        case 'weekly':
            return 'Semanal';
        case 'monthly':
            return 'Mensal';
        case 'yearly':
            return 'Anual';
        default:
            return '';
    }
}