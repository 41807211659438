import React from 'react';
import withRouter from '../../Components/Common/withRouter';
import '../../../assets/scss/customLoginScss.scss';
import logoLight from "../../assets/images/Congresso_Nacional.jpg";

const ParticlesAuth = ({ children }) => {
    return (
        <React.Fragment>
            <div className="auth-page-wrapper pt-5">
                <div className="auth-one-bg-position auth-one-bg" id="auth-particles">

                    <div>
                        <img src={logoLight} alt="" className="blurred-image" style={{ width: "100%"}}/>
                    </div>
                </div>

                {/* pass the children */}
                {children}

                <footer className="footer start-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 text-center">
                                <p className="mb-0">{new Date().getFullYear()} &copy;  Geld.</p>
                            </div>
                            <div className="col-lg-6 text-center">
                                Desenvolvido pela <a href='https://tecnologiadc.com.br' target='blank' className='link-warning'>DC Tecnologia</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ParticlesAuth);