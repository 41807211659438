// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard1 {
    display: flex;
    justify-content: space-between; /* Adjust as per your desired spacing */
    align-items: center;
  }
  
  .card1 {
    flex-basis: 30%; /* Adjust as per your desired width */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    text-align: center;
  }
  
.title1 {
    margin-bottom: 8px;
  }
  
.button1 {
    margin-top: 16px;
  }`, "",{"version":3,"sources":["webpack://./src/_velzon/pages/DashboardAnalytics/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B,EAAE,uCAAuC;IACvE,mBAAmB;EACrB;;EAEA;IACE,eAAe,EAAE,qCAAqC;IACtD,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;EACpB;;AAEF;IACI,kBAAkB;EACpB;;AAEF;IACI,gBAAgB;EAClB","sourcesContent":[".dashboard1 {\n    display: flex;\n    justify-content: space-between; /* Adjust as per your desired spacing */\n    align-items: center;\n  }\n  \n  .card1 {\n    flex-basis: 30%; /* Adjust as per your desired width */\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    padding: 16px;\n    text-align: center;\n  }\n  \n.title1 {\n    margin-bottom: 8px;\n  }\n  \n.button1 {\n    margin-top: 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
