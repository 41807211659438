// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*  .filepond--item-panel {
    width: 100%;
    height: 100%;
  }
  
  .filepond--file-panel {
    width: 200px;
    height: 200px;
  } */
  
/*   .filepond--custom--class {
    height: 200px !important;
    width: 200px !important;

  } */
  .filepond-container {
    width: 300px;
    height: 200px;
}
/* .filepond--image-preview-wrapper {
    width: 250px;
    height: 150px;
}
.filepond--file {
    width: 280px;
    height: 180px;
} */
/* .filepond-item {
    width: 100% !important;
    height: 100% !important;
  }
   */
  .filepond--custom--class .filepond-item {
    width: 100% !important;
    height: 100% !important;
  }
  .filepond-photo .filepond--drop-label{
    padding-top: 100px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Users/filepondCustom.css"],"names":[],"mappings":"AAAA;;;;;;;;KAQK;;AAEL;;;;KAIK;EACH;IACE,YAAY;IACZ,aAAa;AACjB;AACA;;;;;;;GAOG;AACH;;;;IAII;EACF;IACE,sBAAsB;IACtB,uBAAuB;EACzB;EACA;IACE,kBAAkB;EACpB","sourcesContent":["/*  .filepond--item-panel {\n    width: 100%;\n    height: 100%;\n  }\n  \n  .filepond--file-panel {\n    width: 200px;\n    height: 200px;\n  } */\n  \n/*   .filepond--custom--class {\n    height: 200px !important;\n    width: 200px !important;\n\n  } */\n  .filepond-container {\n    width: 300px;\n    height: 200px;\n}\n/* .filepond--image-preview-wrapper {\n    width: 250px;\n    height: 150px;\n}\n.filepond--file {\n    width: 280px;\n    height: 180px;\n} */\n/* .filepond-item {\n    width: 100% !important;\n    height: 100% !important;\n  }\n   */\n  .filepond--custom--class .filepond-item {\n    width: 100% !important;\n    height: 100% !important;\n  }\n  .filepond-photo .filepond--drop-label{\n    padding-top: 100px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
