import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Spinner, UncontrolledDropdown, Row } from 'reactstrap';
import { api } from '../../utils/api';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from "react-router-dom";
import CustomPagination from "../../components/customPagination.js"

const BaseExample = ({
  tableData,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
  total,
  isLoading,
  handleSort,
  onExportExcel,
  onExportPdf,
}) => {
  const tableRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [userLogged, setUserLogged] = useState({
    active: "",
    cell: "",
    created_at: "",
    email: "",
    email_verified_at: null,
    id: 1,
    name: "",
    photo: "",
    updated_at: "",
    user_type: ""
  });
  const [isAdmin, setIsAdmin] = useState(false);


  useEffect(() => {
    fetchData()
    setFilteredData(tableData); // Initialize filteredData with the original tableData
  }, [tableData]);

  const handleDelete = async () => {

    const responseVoter = await api.get(`/getUser/${voterId}/delete`);

    console.log('Delete row:', rowIndex);
    window.location.reload();

  };
  const handleEdit = (rowIndex) => {
    console.log('Edit row:', rowIndex);
  };

  const handleExportExcel = async () => {
    setIsExportingExcel(true);

    await onExportExcel();

    setIsExportingExcel(false);
  };

  const handleExportPDF = async () => {
    setIsExportingPdf(true);

    await onExportPdf();

    setIsExportingPdf(false);
  };

  const fetchData = async () => {
      try {
          const responseUser = await api.get(`/getUserLogged`); // pega usuario logado
          setUserLogged(responseUser.data ?? null)
          setIsAdmin(userLogged.user_type == "administrator") // verifica se é administrador
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const handleFormSubmit = async (formData) => {
    try {
      // Send form data to the server
      await api.post('/submitForm', formData);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>Nome</span>,
      selector: row => row.name,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Email</span>,
      selector: row => row.email,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Tipo de Usúario</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.user_type) {
          case 'administrator':
            return <span className="badge bg-info"> Administrador </span>;
          case 'user':
            return <span className="badge bg-info"> Usúario </span>;
          default:
            return <span className="badge bg-info"> Sem Cargo </span>;
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Telefone</span>,
      selector: row => row.cell,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Status</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.active) {
          case 'ativo':
            return <span className="badge bg-success">Ativo</span>;
          case 'inativo':
            return <span className="badge bg-danger">Inativo</span>;
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Ação</span>,
      sortable: true,

      cell: (row) => {
        const { id } = row;
        return (
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem className='edit-item-btn'><Link to={`/editar-usuário/${id}`}><i className="ri-edit-2-line"></i>Editar</Link></DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];
  const customButtonStyle = {
    marginRight: '10px',
  };
  const handleFilter = (e) => {
    var searchValue = e.target.value.toLowerCase();
    if (searchValue == "administrador") {
      var searchValue = 'administrator';
    }
    if (searchValue == "usuario") {
      var searchValue = 'users';
    }
    const filteredTableData = tableData.filter((item) => {
      for (const key in item) {
        if (item[key] && item[key].toString().toLowerCase().includes(searchValue)) {
          return true;
        }
      }
      return false;
    });
    setFilteredData(filteredTableData);
  };
  const exportButtons = (
    isAdmin ? (
      <Row>
        <div className='d-flex justify-content-between'>
          <div>
            <button className="btn btn-primary btn-animation waves-effect waves-light" onClick={handleExportExcel} style={customButtonStyle}>
              Excel
              {isExportingExcel && (
                <Spinner size="sm" style={{ marginLeft: 6 }} />
              )}
            </button>
            <button className="btn btn-primary btn-animation waves-effect waves-light" onClick={handleExportPDF} style={customButtonStyle}>
              PDF
              {isExportingPdf && (
                <Spinner size="sm" style={{ marginLeft: 6 }} />
              )}
            </button>
          </div>
          <div className="d-flex align-items-center">
            <i className="ri-search-line p-2"></i> <Input type="text" placeholder="Buscar" onChange={handleFilter} />
          </div>
        </div>
      </Row>
    ) : null
  );
  return (
    <>
      {exportButtons}
      <div ref={tableRef}>
        <DataTable columns={columns} data={filteredData} pagination
          onSort={handleSort}
          paginationServer
          sortServer
          defaultSortFieldId={5}
          defaultSortAsc
          progressPending={isLoading}
          progressComponent={<Spinner />}
          noDataComponent={<div>Nenhum registro encontrado</div>}
          paginationTotalRows={total}
          paginationPerPage={rowsPerPage}
          paginationComponent={CustomPagination}
          paginationComponentOptions={{
            rowsPerPageText: 'Dados por Pagina:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        >
        </DataTable>
      </div>
    </>
  );
};

export default BaseExample;
